import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RoleGaurdService {

  constructor(private authenticationService:AuthenticationService, private router: Router, 
    private jwtHelper:JwtHelperService, private http:HttpClient, private toastrService:ToastrService) {
  }
  baseUrl:string= environment.baseUrl;
  

    canActivate(){
      //this.authenticationService.refreshToken();
    if( this.authenticationService.isAdmin()){
      return true;
    }else{
      //this.authenticationService.refreshToken();
      this.router.navigate(["login"]);
    }
      
      }
}
