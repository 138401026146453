<h5 class="mt-5">Up doctorFromDetail Records</h5>

<div class="alert alert-info" *ngIf="doctorFromDetailEdit.dirty">
    <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
</div>

<form #doctorFromDetailEdit="ngForm" (ngSubmit)="updateDoctor()" id="doctorFromDetailEdit">
    <div class="row">
        <div class="col-4">
            <h6> First Name: </h6>
            <input type="text" class="form-control" name="firstName" [(ngModel)]="doctorFromDetail.firstName">
        </div>
        <div class="col-4">
            <h6> Last Name: </h6>
            <input type="text" class="form-control" name="lastName" [(ngModel)]="doctorFromDetail.lastName">
        </div>
        <div class="col-4">
            <h6> Title: </h6>
            <input type="text" class="form-control" name="title" [(ngModel)]="doctorFromDetail.title">
        </div>
      
    </div>
    <div class="row">
        <div class="col-4">
            <h6> Date Of Birth: </h6>
            <input type="text" class="form-control" name="dateOfBirth" [(ngModel)]="doctorFromDetail.dateOfBirth">
        </div>
        <div class="col-4">
            <h6> Gender: </h6>
            <input type="text" class="form-control" name="gender" [(ngModel)]="doctorFromDetail.gender">
        </div>
        <div class="col-4">
            <h6> ID Number: </h6>
            <input type="text" class="form-control" name="nationalIdentityNumber" [(ngModel)]="doctorFromDetail.nationalIdentityNumber">
        </div>
      
    </div>
    <div class="row mt-2">
        <div class="col-6">
            <h6> Phone Number: </h6>
            <input type="text" class="form-control" name="phone" [(ngModel)]="doctorFromDetail.phone">
        </div>
        <div class="col-6">
            <h6> Email: </h6>
            <input type="text" class="form-control" name="emailAddress" [(ngModel)]="doctorFromDetail.emailAddress">
        </div>
        
      
    </div>
    <div class="row mt-2">
        <div class="col-4">
            <h6> Passport Number: </h6>
            <input type="text" class="form-control" name="passportNumber" [(ngModel)]="doctorFromDetail.passportNumber">
        </div>
        <div class="col-4">
            <h6> Employer: </h6>
            <input type="text" class="form-control" name="employer" [(ngModel)]="doctorFromDetail.employer">
        </div>
        <div class="col-4">
            <h6> Occupation: </h6>
            <input type="text" class="form-control" name="occupation" [(ngModel)]="doctorFromDetail.occupation">
        </div>
      
    </div>
    <h5 class="mt-4">Physical Address</h5>
    <div class="row">
       
        <div class="col-2">
            <h6> Unit No: </h6>
            <input type="text" class="form-control" name="unitNumber" [(ngModel)]="doctorFromDetail.physicalAddress.unitNumber">
        </div>
        <div class="col-3">
            <h6> Unit Name: </h6>
            <input type="text" class="form-control" name="unitName" [(ngModel)]="doctorFromDetail.physicalAddress.unitName">
        </div>
        <div class="col-4">
            <h6> Street Address: </h6>
            <input type="text" class="form-control" name="streetAddress" [(ngModel)]="doctorFromDetail.physicalAddress.streetAddress">
        </div>
        <div class="col-3">
            <h6> Postal Code </h6>
            <input type="text" class="form-control" name="postalCode" [(ngModel)]="doctorFromDetail.physicalAddress.postalCode">
        </div>
      
    </div>
    <div class="row mt-2">
       
        <div class="col-3">
            <h6> Optional Address Line: </h6>
            <input type="text" class="form-control" name="addressLineTwo" [(ngModel)]="doctorFromDetail.physicalAddress.addressLineTwo">
        </div>
        <div class="col-3">
            <h6> Suburb: </h6>
            <input type="text" class="form-control" name="suburb" [(ngModel)]="doctorFromDetail.physicalAddress.suburb">
        </div>
        <div class="col-3">
            <h6> province: </h6>
            <input type="text" class="form-control" name="province" [(ngModel)]="doctorFromDetail.physicalAddress.province">
        </div>
        <div class="col-3">
            <h6> Country: </h6>
            <input type="text" class="form-control" name="country" [(ngModel)]="doctorFromDetail.physicalAddress.country">
        </div>
      
    </div>
    <h3 class="mt-2"> Medical Aid Details</h3>
    <div class="row ">
       
        <div class="col-4">
            <h6> Medical Aid Provider: </h6>
            <input type="text" class="form-control" name="addressLineTwo" [(ngModel)]="doctorFromDetail.medicalAid.medicalAidName">
        </div>
        <div class="col-4">
            <h6> Medical Aid No: </h6>
            <input type="text" class="form-control" name="medicalAidNumber" [(ngModel)]="doctorFromDetail.medicalAid.medicalAidNumber">
        </div>
        <div class="col-4">
            <h6> Main Member: {{'  '+doctorFromDetail.medicalAid.medicalAidMainMember}}</h6>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="medicalAidMainMember" value="true" id="flexRadioDefault1" 
                 [(ngModel)]="doctorFromDetail.medicalAid.medicalAidMainMember">
                <label class="form-check-label" for="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="medicalAidMainMember" value="false" id="flexRadioDefault2" 
                [(ngModel)]="doctorFromDetail.medicalAid.medicalAidMainMember">
                <label class="form-check-label" for="flexRadioDefault2">
                  No
                </label>
              </div>
        </div>
        
      
    </div>
    <div *ngIf="!doctorFromDetail.medicalAid.medicalAidMainMember"> <!-- fill in form for none holder doctorFromDetails-->

        <div class="row mt-3">
       
            <div class="col-4">
                <h6> Holder Full Name: </h6>
                <input type="text" class="form-control" name="medicalAidMainMemberFullName" [(ngModel)]="doctorFromDetail.medicalAid.medicalAidMainMemberFullName">
            </div>
            <div class="col-4">
                <h6>Holder ID No: </h6>
                <input type="text" class="form-control" name="medicalAindMainMemberIdentityNumber" [(ngModel)]="doctorFromDetail.medicalAid.medicalAindMainMemberIdentityNumber">
            </div>
            <div class="col-4">
                <h6> Occupation: </h6>
                <input type="text" class="form-control" name="occupation" [(ngModel)]="doctorFromDetail.medicalAid.occupation">
            </div>
            
          
        </div>
        <div class="row mt-3">
           
           
            <div class="col-6">
                <h6>Holder ID No: </h6>
                <input type="text" class="form-control" name="residentaillAddress" [(ngModel)]="doctorFromDetail.medicalAid.residentaillAddress">
            </div>
            <div class="col-6">
                <h6> Holder Occupation: </h6>
                <input type="text" class="form-control" name="postalAddress" [(ngModel)]="doctorFromDetail.medicalAid.occupation">
            </div>
            
          
        </div>
        <div class="row">
           
            <div class="col-6">
                <h6> Holder Employer : </h6>
                <input type="text" class="form-control" name="employer" [(ngModel)]="doctorFromDetail.medicalAid.employer">
            </div>
            <div class="col-6">
                <h6>Holder ID  Phone: </h6>
                <input type="text" class="form-control" name="phoneNumber" [(ngModel)]="doctorFromDetail.medicalAid.phoneNumber">
            </div>
         
        </div>
    

    </div><!--End of medical holder details-->
    <h3 class="mt-3">Next Of Kin Details</h3>
    <div class="row mt-1">
           
        <div class="col-4">
            <h6> Full Name : </h6>
            <input type="text" class="form-control" name="nextOfKinFullName" [(ngModel)]="doctorFromDetail.nextOfKinFullName">
        </div>
        <div class="col-4">
            <h6> ID No: </h6>
            <input type="text" class="form-control" name="nextOfKinNextOfKinIdentity" [(ngModel)]="doctorFromDetail.nextOfKinNextOfKinIdentity">
        </div>
        <div class="col-4">
            <h6> Phone No: </h6>
            <input type="text" class="form-control" name="nextOfKinPhoneNumber" [(ngModel)]="doctorFromDetail.nextOfKinPhoneNumber">
        </div>
     
    </div>
    <div class="row mt-2">
           
        <div class="col-5">
            <h6> Email Address : </h6>
            <input type="text" class="form-control" name="nextOfKinEmailAddress" [(ngModel)]="doctorFromDetail.nextOfKinEmailAddress">
        </div>
        <div class="col-7">
            <h6> Address: </h6>
            <input type="text" class="form-control" name="nextOfKinAddress" [(ngModel)]="doctorFromDetail.nextOfKinAddress">
        </div>
     
    </div>
<button [disabled]="!doctorFromDetailEdit.dirty" class="btn btn-primary mt-3"> Save Changes</button>
 

</form>
