 
      <div>
        <h3>Hi</h3>
      </div>  
      <hr>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class=" nav-item "><a class="nav-link d-inline " routerLink="/dashboard" routerLinkActive="active">
                  <i class="fa fa-tachometer d-inline mr-2 i-lable" aria-hidden="true"></i> <span>Dashboard</span></a></li>
            <li class="mt-2"><i class="fa fa-user-md d-inline mr-2 i-lable" aria-hidden="true"></i><h6 class="d-inline">Manage Doctors</h6></li>
            <li class="nav-item mt-0 ml-3"><a class="nav-link " routerLink="/doctors" routerLinkActive="active">
                  <i class="fa fa-folder-open-o mr-2" aria-hidden="true"></i><span>View Doctors</span> </a></li>
            <li class="nav-item mt-0 ml-3"><a class="nav-link " routerLink="/new-doctor" routerLinkActive="active">
                  <i class="fa fa-plus mr-2" aria-hidden="true"></i><span>Add Doctor</span> </a></li>

            <li><i class="fa fa-user d-inline mr-2 i-lable" aria-hidden="true"></i><h6 class="d-inline">Manage Patients</h6></li>
            <li class="nav-item  ml-3"><a class="nav-link " routerLink="/patients" routerLinkActive="active">
                  <i class="fa fa-folder-open-o mr-2" aria-hidden="true"></i><span>View Patients</span></a></li>
            <li class="nav-item  ml-3"><a class="nav-link " routerLink="/new-patient" routerLinkActive="active">
                  <i class="fa fa-plus mr-2" aria-hidden="true"></i><span>Add Patient</span></a></li>

       <li><i class="fa fa-user d-inline mr-2 i-lable" aria-hidden="true"></i><h6 class="d-inline">Manage Appointments</h6></li>
       <li class="nav-item  ml-3"><a class="nav-link " routerLink="/appointment" routerLinkActive="active">
            <i class="fa fa-plus mr-2" aria-hidden="true"></i><span>View Appointments</span></a></li>
           
            
      </ul>

    