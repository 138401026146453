<div class="row container mt-5" >
 
    
    <main class="col-12">
        <h5 class="mt-5">Add New Doctor</h5>

        <div class="alert alert-info" *ngIf="createDoctorForm.touched">
            <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
        </div>
        
        <form [formGroup]="createDoctorForm"  (ngSubmit)="addNewDoctor()" id="doctorAdd">
            <div class="row">
                
                <div class="col-4">
                    <h6> First Name: </h6>
                    <input type="text" class="form-control" name="firstName" formControlName="firstName" >
                </div>
                <div class="col-4">
                    <h6> Last Name: </h6>
                    <input type="text" class="form-control" name="lastName" formControlName="lastName" >
                </div>
                <div class="col-4">
                    <h6> Title: </h6>
                    <input type="text" class="form-control" name="title" formControlName="title">
                </div>
              
            </div>
            <div class="row">
                <div class="col-4">
                    <h6> Date Of Birth: </h6>
                    <input type="text" class="form-control" name="dateOfBirth" formControlName="dateOfBirth" >
                </div>
                <div class="col-4">
                    <h6> Gender: </h6>
                    <input type="text" class="form-control" name="gender" formControlName="gender">
                </div>
                <div class="col-4">
                    <h6> ID Number: </h6>
                    <input type="text" class="form-control" name="nationalIdentityNumber" formControlName="nationalIdentityNumber" >
                </div>
              
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <h6> Phone Number: </h6>
                    <input type="text" class="form-control" name="phone"  formControlName="phone">
                </div>
                <div class="col-6">
                    <h6> Email: </h6>
                    <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress">
                </div>
                
              
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <h6> Passport Number: </h6>
                    <input type="text" class="form-control" name="passportNumber" formControlName="passportNumber" >
                </div>
                <div class="col-4">
                    <h6> Employer: </h6>
                    <input type="text" class="form-control" name="employer" formControlName="employer">
                </div>
                <div class="col-4">
                    <h6> Occupation: </h6>
                    <input type="text" class="form-control" name="occupation"  formControlName="occupation">
                </div>
              
            </div>
            <h5 class="mt-4">Physical Address</h5>
            <div formGroupName="physicalAddress">
                <div class="row" ><!--STart of Physical Adddress-->
               
                    <div class="col-2">
                        <h6> Unit No: </h6>
                        <input type="number" class="form-control" name="unitNumber" formControlName="unitNumber" >
                    </div>
                    <div class="col-3">
                        <h6> Unit Name: </h6>
                        <input type="text" class="form-control" name="unitName" formControlName="unitName" >
                    </div>
                    <div class="col-4">
                        <h6> Street Address: </h6>
                        <input type="text" class="form-control" name="streetAddress" formControlName="streetAddress" >
                    </div>
                    <div class="col-3">
                        <h6> Postal Code </h6>
                        <input type="text" class="form-control" name="postalCode" formControlName="postalCode">
                    </div>
                  
                </div>
                <div class="row mt-2">
                   
                    <div class="col-3">
                        <h6> Optional Address Line: </h6>
                        <input type="text" class="form-control" name="addressLineTwo" formControlName="addressLineTwo" >
                    </div>
                    <div class="col-3">
                        <h6> Suburb: </h6>
                        <input type="text" class="form-control" name="suburb" formControlName="suburb">
                    </div>
                    <div class="col-3">
                        <h6> province: </h6>
                        <input type="text" class="form-control" name="province" formControlName="province">
                    </div>
                    <div class="col-3">
                        <h6> Country: </h6>
                        <input type="text" class="form-control" name="country" formControlName="country" >
                    </div>
                  
                </div><!--End of Physical Address-->
            </div>
            
            <div formGroupName="medicalAid">
                <h3 class="mt-2"> Medical Aid Details</h3>
                <div class="row ">
                   
                    <div class="col-4">
                        <h6> Medical Aid Provider: </h6>
                        <input type="text" class="form-control" name="medicalAidName" formControlName="medicalAidName" >
                    </div>
                    <div class="col-4">
                        <h6> Medical Aid No: </h6>
                        <input type="text" class="form-control" name="medicalAidNumber" formControlName="medicalAidNumber" >
                    </div>
                    <div class="col-4">
                        <h6> Main Member: </h6>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="medicalAidMainMember" value="true" id="flexRadioDefault1" 
                             formControlName="medicalAidMainMember">
                            <label class="form-check-label" for="flexRadioDefault1">
                              Yes
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="medicalAidMainMember" value="false" id="flexRadioDefault2" 
                            formControlName="medicalAidMainMember">
                            <label class="form-check-label" for="flexRadioDefault2">
                              No
                            </label>
                          </div>
                    </div>
                    
                  
                </div>
                <div> <!-- fill in form for none holder patients-->
            
                    <div class="row mt-3">
                   
                        <div class="col-4">
                            <h6> Holder Full Name: </h6>
                            <input type="text" class="form-control" name="medicalAidMainMemberFullName" formControlName="medicalAidMainMemberFullName" >
                        </div>
                        <div class="col-4">
                            <h6>Holder ID No: </h6>
                            <input type="text" class="form-control" name="medicalAindMainMemberIdentityNumber" formControlName="medicalAindMainMemberIdentityNumber" >
                        </div>
                        <div class="col-4">
                            <h6> Occupation: </h6>
                            <input type="text" class="form-control" name="occupation" formControlName="occupation">
                        </div>
                        
                      
                    </div>
                    <div class="row mt-3">
                       
                       
                        <div class="col-6">
                            <h6>Holder ID No: </h6>
                            <input type="text" class="form-control" name="residentaillAddress" formControlName="residentaillAddress">
                        </div>
                        <div class="col-6">
                            <h6> Holder Occupation: </h6>
                            <input type="text" class="form-control" name="postalAddress" formControlName="postalAddress">
                        </div>
                        
                      
                    </div>
                    <div class="row">
                       
                        <div class="col-6">
                            <h6> Holder Employer : </h6>
                            <input type="text" class="form-control" name="employer"  formControlName="employer">
                        </div>
                        <div class="col-6">
                            <h6>Holder ID  Phone: </h6>
                            <input type="text" class="form-control" name="phoneNumber" formControlName="phoneNumber" >
                        </div>
                     
                    </div>
                
            
                </div><!--End of medical holder details-->
            </div>
            
            <h3 class="mt-3">Next Of Kin Details</h3>
            <div class="row mt-1">
                   
                <div class="col-4">
                    <h6> Full Name : </h6>
                    <input type="text" class="form-control" name="nextOfKinFullName"  formControlName="nextOfKinFullName">
                </div>
                <div class="col-4">
                    <h6> ID No: </h6>
                    <input type="text" class="form-control" name="nextOfKinNextOfKinIdentity"  formControlName="nextOfKinNextOfKinIdentity">
                </div>
                <div class="col-4">
                    <h6> Phone No: </h6>
                    <input type="text" class="form-control" name="nextOfKinPhoneNumber" formControlName="nextOfKinPhoneNumber" >
                </div>
             
            </div>
            <div class="row mt-2">
                   
                <div class="col-5">
                    <h6> Email Address : </h6>
                    <input type="text" class="form-control" name="nextOfKinEmailAddress" formControlName="nextOfKinEmailAddress">
                </div>
                <div class="col-7">
                    <h6> Address: </h6>
                    <input type="text" class="form-control" name="nextOfKinAddress"  formControlName="nextOfKinAddress">
                </div>
             
            </div>
        <button  class="btn btn-primary mt-3"> Save Changes</button>
         
        
        </form>
        <p>{{createDoctorForm.value|json}}</p>
    </main>
    </div>