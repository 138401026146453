import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Person } from 'src/app/interfaces/person';
import { PersonService } from 'src/app/_services/person.service';
const personType:string='doctor';

@Component({
  selector: 'app-doctor-new',
  templateUrl: './doctor-new.component.html',
  styleUrls: ['./doctor-new.component.css']
})
export class DoctorNewComponent implements OnInit {
  @ViewChild('patientAdd') patientAdd:NgForm;
  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.patientAdd.dirty){
      $event.returnValue=true;
    }
  }
  createDoctorForm:FormGroup;
  doctor:Person;

  constructor(private toastService:ToastrService,private formBuilder:FormBuilder,private personService:PersonService,private route:Router) { }

  ngOnInit(): void {
    this.initilizeDoctorFom();
  }

  initilizeDoctorFom(){
    this.createDoctorForm= new FormGroup(
      {
       
        
        firstName:new FormControl(),
        lastName : new FormControl(),
        phone:new FormControl(),
        emailAddress: new FormControl(),
        nationalIdentityNumber:new FormControl(),
        passportNumber: new FormControl(),
        occupation:new FormControl(),
        employer:new FormControl(),
        gender :new FormControl(),
        title :new FormControl(),
        dateOfBirth:new FormControl(),
        nextOfKinNextOfKinIdentity:new FormControl(),
        nextOfKinFullName: new FormControl(),
        nextOfKinEmailAddress: new FormControl(),
        nextOfKinPhoneNumber : new FormControl(),
        nextOfKinAddress:new FormControl(),
        nextOfKinRelationship : new FormControl(),
        physicalAddress:new FormGroup({
          unitNumber:new FormControl(),
          unitName : new FormControl(),
          streetAddress : new FormControl(),
          addressLineTwo :new FormControl(),
          postalCode : new FormControl(),
          suburb : new FormControl(),
          province :new FormControl(),
          country : new FormControl()
        })
       ,
        medicalAid:new FormGroup({
          medicalAidName  : new FormControl(),
          medicalAidNumber  : new FormControl(),
          medicalAidMainMember  : new FormControl(),
          medicalAidMainMemberFullName  : new FormControl(),
          medicalAindMainMemberIdentityNumber  : new FormControl(),
          occupation  : new FormControl(),
          employer  : new FormControl(),
          residentaillAddress  :new FormControl(),
          postalAddress  :new FormControl(),
          phoneNumber  : new FormControl()
        })
        
      }


    )
  }
  addNewDoctor(){
 
    this.personService.addPerson(this.createDoctorForm.value,personType).subscribe(doctorResponse=>{
      this.doctor=doctorResponse;
      this.toastService.success('Doctor with Id: '+this.doctor.personID+ 'hass been successfully created.', 'Success')
      this.route.navigate(['doctors/'+this.doctor.personID])
    },error=>{
      this.toastService.error(error.error);
      
    });
  }

}
