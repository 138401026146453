<div class="row container mt-1">
  
    
    <main class="col-12">
      <div class="heading"> 
        <h3>PATIENT LIST</h3>
      </div>
      
      <form class =" " #searchForm="ngForm" (ngSubmit)="loadPatients()" autocomplete="off"> 
                    
        <div class="mb-3">
            <label for="InputEmail1" class="form-label Search">Search by patient ID number, email or phone number</label>
            <input name="searchTerm"
            [(ngModel)]="searchTerm"
            type="text" class="form-control" placeholder="Enter search term here.." >
            
          </div>
        
   
  </form>
        <div class="table-responsive">
          <table class="table table-striped table-sm" *ngIf="patients?.length>0;  else elseBlock">
            <thead>
              <tr>
                <th>ID#</th>
                <th>Fullname</th>
                <th>Phone</th>
                <th>emailAddress</th>
                <th>Gender</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let patient of patients" routerLink='/patients/{{patient.patientID}}' >
                  <td>{{patient.nationalIdentityNumber}}</td>
                  <td>{{patient.firstName +" "+ patient.lastName}} </td>
                  <td>{{patient.phone}}</td>
                  <td>{{patient.emailAddress}}</td>
                  <td>{{patient.gender}}</td>
                  <td>

                    <button *ngIf="authService.isSuperAdmin()" type="button" 
                    class="btn btn-danger btn-sm" (click)="deletePatient(patient)">Delete</button>
                  </td>
                
                  
                </tr>
            </tbody>
        </table>
        <ng-template #elseBlock>
          <div class="alert alert-info" role="alert">
           Oops!! No records found.
          </div>

        </ng-template>
        </div>

        <div class="d-flex justify-content-center" *ngIf="pagination">
            <pagination [boundaryLinks]="true"
             [totalItems]="pagination.totalCount"
             [itemsPerPage]="pagination.pageSize"
             [(ngModel)]="pagination.currentPage"
             (pageChanged)="pageChanged($event)"
                previousText="Previous" nextText="Next" firstText="First" lastText="Last">
            </pagination>
        </div>
    </main>
    </div>