<div class="row container " *ngIf="staff" >
   
      <main class="col-12">
        <div class="heading"> 
          <h2>Staff Details</h2>
        </div>
          <div>
              <tabset>
                <tab heading="Basic Information" id="tab1" >
                  <div class="row">
  
                  <h4 class="mt-5 col" >Personal Details</h4> 
                  
                  </div>
                  
                  <hr>
                  <table class="table table-bordered">
                    
                    <tbody>
                      <tr>
                      
                        <td colspan="0.5"><label for="staff" class="fw-bold">Full Name :</label> {{"  "+ staff.firstName + " " + staff.lastName}}</td>
                   
                        <td><label for="staff" class="fw-bold">Title :</label> {{"  " + staff.title!==null? staff.title:'...'}}</td>
                        <td><label for="staff" class="fw-bold">D.O.B : </label>{{"   " +staff.dateOfBirth |date: '  dd/MM/yyyy'}}</td>
                      </tr>
                      <tr>
                       
                        <td ><label for="staff" class="fw-bold">Gender :  </label>{{"   " +staff.gender}}</td>
                        <td ><label for="staff" class="fw-bold"> ID No :  </label>{{"   " +staff.nationalIdentityNumber}}</td>
                        <td ><label for="staff" class="fw-bold">Passport :  </label>{{"   " +staff.passportNumber!==null? staff.passportNumber:'' | uppercase}}</td>
                      </tr>
                      <tr>
                        <td ><label for="staff" class="fw-bold"> Employer :  </label>{{"   " +staff.employer}}</td>
                        <td ><label for="staff" class="fw-bold">Occupation :  </label>{{"   " +staff.occupation}}</td>
                        <td ><label for="staff" class="fw-bold"> Contact Number :  </label>{{"   " +staff.practiceNumber}}</td>
                      </tr>
                    
                      <tr>
                        <td ><label for="staff" class="fw-bold"> Contact Number :  </label>{{"   " +staff.phone}}</td>
                        <td ><label for="staff" class="fw-bold"> Position :  </label>{{"   " +staff.position}}</td>
                        <td colspan="2"><label for="staff" class="fw-bold">Email :  </label>{{"   " +staff.emailAddress}}</td>
                       
                      </tr>
                      
                    </tbody>
                  </table><!--end of first table basic information-->
  
                  
                   <button (click)="generatePdf()">Download PDF File</button>
                </tab>
               
                <tab *ngIf="role>2" heading="Edit Records"><app-staff-edit [staff]="staff"></app-staff-edit></tab>
                <tab heading = "Task">
                  <div class="table-responsive">
                    <h4 class="mt-5 col" >Task Records</h4> 
                               
                    <hr>
                    
                    <table class="table table-striped table-sm">
                      <thead>
                        <div><i class="fa fa-check" aria-hidden="true"></i><Span class="span-heading">Tasks</Span></div>
                        <tr>
                          <th>Status</th>
                          <th>Start</th>
                          <th>End</th>
                          <th>Task</th>
                          <th>Description</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let task of staff.myTasks">
                          <td>{{task.status}}</td>
                          <td>{{task.startDate | date}}</td>
                          <td>{{task.finishDate |date}}</td>
                          <td>{{task.taskName}}</td>
                          <td>{{task.taskDescription}}</td>
                    
                        </tr>
                      </tbody>
                    </table>
                  </div><!--end of tasks lists-->
                </tab>
               
              </tabset>
            </div>
      </main>
    </div>