import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DesignationsComponent } from './designations/designations.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { AppointmentListComponent } from './pages/appointment-list/appointment-list.component';
import { DoctorDetailComponent } from './pages/doctor-list/doctor-detail/doctor-detail.component';
import { DoctorListComponent } from './pages/doctor-list/doctor-list.component';
import { DoctorNewComponent } from './pages/doctor-new/doctor-new.component';
import { HomeComponent } from './pages/home/home.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { MyTaskListComponent } from './pages/my-task-list/my-task-list.component';
import { InvoicesComponent } from './pages/patient-list/patient-detail/invoices/invoices.component';
import { PatientDetailComponent } from './pages/patient-list/patient-detail/patient-detail.component';
import { PatientFileComponent } from './pages/patient-list/patient-detail/patient-file/patient-file.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientNewComponent } from './pages/patient-new/patient-new.component';
import { PaymentListComponent } from './pages/payment-list/payment-list.component';
import { StaffDetailComponent } from './pages/staff-list/staff-detail/staff-detail.component';
import { StaffListComponent } from './pages/staff-list/staff-list.component';
import { StaffNewComponent } from './pages/staff-new/staff-new.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserRegistrationComponent } from './users/user-registration/user-registration.component';
import { AuthGuardService } from './_services/auth-guard.service';
import { UnsavedChangesGaurdGuard } from './_services/unsaved-changes-gaurd.guard';
import { RoleGaurdService } from './_services/role-gaurd.service';

const routes: Routes = [
  
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

  { path: 'dashboard', component: HomeComponent ,canActivate: [AuthGuardService]},
  { path: 'login', component: LoginComponent },
  { path: 'staff', component: StaffListComponent,canActivate: [AuthGuardService]},
  { path: 'doctors', component: DoctorListComponent ,canActivate: [AuthGuardService]},
  { path: 'staff/:id', component: StaffDetailComponent ,canActivate: [AuthGuardService]},
  { path: 'doctors/:id', component: DoctorDetailComponent ,canActivate: [AuthGuardService]},
  { path: 'patients', component: PatientListComponent ,canActivate: [AuthGuardService]},
  { path: 'patients/:id', component: PatientDetailComponent ,canActivate: [AuthGuardService]},
  { path: 'new-patient', component: PatientNewComponent, canActivate: [AuthGuardService] },
  { path: 'new-staff', component: StaffNewComponent, canActivate: [AuthGuardService,RoleGaurdService] },
  { path: 'my-tasks', component: MyTaskListComponent, canActivate: [AuthGuardService] },
  { path: 'payments', component: PaymentListComponent, canActivate: [AuthGuardService,RoleGaurdService] },
  { path: 'manage-invoices', component: InvoiceListComponent, canActivate: [AuthGuardService,RoleGaurdService] },
  { path: 'appointment', component: AppointmentListComponent, canActivate: [AuthGuardService] },
  { path: 'new-user', component: UserRegistrationComponent, canActivate: [AuthGuardService, RoleGaurdService] },
  { path: 'all-users', component: UserListComponent, canActivate: [AuthGuardService] },
  { path: 'login/forgot-password', component: ForgotPasswordComponent },
  { path: 'login/reset-password', component: ResetPasswordComponent },

  {
    path: '**',
    redirectTo: '/dashboard'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
