import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../interfaces/pagination';
import { Payment } from '../interfaces/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  baseurl:string= environment.baseUrl;
 
 payment:Payment;

  paymentPaginatedResult:PaginatedResult<Payment[]>= new PaginatedResult<Payment[]>();
  

  constructor(private http:HttpClient) { }

  getPayments(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Payment[]>(this.baseurl +'payments',{observe:'response',params}).pipe(
      map(response=>{
        this.paymentPaginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paymentPaginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        console.log(JSON.parse(response.headers.get('pagination')));
        return this.paymentPaginatedResult;
      })
    )
  }
  getPaymentByID(paymentID:number){
    return this.http.get<Payment>(this.baseurl + 'payments/'+paymentID);
  }

  createPayment(invoiceID:number,payment:any){
    return this.http.post<Payment>(this.baseurl+ 'payments/'+invoiceID,payment);
  }

  updatePayment(paymentID:number,payment:any){
    return this.http.put(this.baseurl+ 'payments/'+paymentID,payment);
  }
}
