import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Mytask } from 'src/app/interfaces/mytask';
import { Pagination } from 'src/app/interfaces/pagination';
import { Person } from 'src/app/interfaces/person';
import { Staff } from 'src/app/interfaces/staff';
import { AdminService } from 'src/app/_services/admin.service';
import { PersonService } from 'src/app/_services/person.service';
import { StaffService } from 'src/app/_services/staff.service';
const doctor='doctor';

@Component({
  selector: 'app-my-task-list',
  templateUrl: './my-task-list.component.html',
  styleUrls: ['./my-task-list.component.css']
})
export class MyTaskListComponent implements OnInit {
  tasks:Mytask[];
  pageNumber?:number=1;
  pageSize?:number=50;
  searchTerm?:string=null;
  public paginationMyTask:Pagination;
  createTaskForm:FormGroup;
  doctors:Staff[];
  personId:number;
  mytask:Mytask;
  taskStatuses:string[]=['Not Started','In Progress','Completed'];
  selectedTask?:Mytask;
  taskName:FormControl;

  
 modalRef: BsModalRef;

  
 
 openModal(templateCreateTask: TemplateRef<any>) {
   this.modalRef = this.modalService.show(templateCreateTask,{class: 'modal-lg'});
   
 }
hideModal(){
 this.modalRef.hide()

 
}
openModalUpdateTask(updateTaskTemplate: TemplateRef<any>,task:Mytask) {


  this.selectedTask=task;
   this.createTaskForm.patchValue({'taskName':this.selectedTask.taskName,
   'taskDescription':this.selectedTask.taskDescription,
   'status':this.selectedTask.status,
   'startDate': this.datepipe.transform(this.selectedTask.startDate, 'yyyy-MM-dd'),
   'finishDate':this.datepipe.transform(this.selectedTask.finishDate, 'yyyy-MM-dd'),
   'staffID':this.selectedTask.staffID})
   //this.createTaskForm.setValue({'taskDescription':this.selectedTask.taskDescription})
   //this.createTaskForm.setValue({'status':this.selectedTask.status})
   //this.createTaskForm.setValue({'startDate':this.selectedTask.startDate})
   //this.createTaskForm.setValue({'finishDate':this.selectedTask.finishDate})
   //this.createTaskForm.setValue({'personID':this.selectedTask.personID})
  this.modalRef = this.modalService.show(updateTaskTemplate,{class: 'modal-lg'});
 // console.log(this.selectedTask);

}

  constructor(private adminService:AdminService, private toastService:ToastrService,
    private modalService:BsModalService,
    private personService:PersonService,public datepipe: DatePipe,
    private formBuilder:FormBuilder, private staffService:StaffService) { }

  ngOnInit(): void {
    this.getTasks();
    this.loadDoctors();
    this.initializeTaskForm();
     
  }

  newTask(){
 
   this.adminService.createTask(this.createTaskForm.get('staffID').value, 
   this.createTaskForm.value).subscribe(response=>{
    this.mytask=response;
    this.createTaskForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },2000);
    this.toastService.success("Task Added Successfully","Success")
   }, error=>{
     this.toastService.error(error);
   })
   //console.log(this.createTaskForm.value)
  }
  updateTask(){
    this.adminService.updateTask(this.selectedTask.myTaskID,
      this.createTaskForm.value).subscribe(()=>{
        this.toastService.success('Task Updated successfully','Task Update');
        setTimeout(()=>{
          window.location.reload();
        },3000);
      },error=>{
        this.toastService.error(error);
        
      });

  }

  initializeTaskForm(){
    this.createTaskForm= new FormGroup(
      {
       
    /*taskName: new FormControl(),
    taskDescription: new FormControl(),
    status:new FormControl(),
    startDate: new FormControl(),
    finishDate: new FormControl(),
    personID: new FormControl(),*/
    taskName: new FormControl(),
    taskDescription: new FormControl(),
    status:new FormControl(),
    startDate: new FormControl(),
    finishDate: new FormControl(),
    staffID: new FormControl(),
      }
    )
  }
  getTasks(){
    this.adminService.getTasks(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
      this.tasks=response.result;
      this.paginationMyTask=response.pagination;
    },error=>{
      this.toastService.error(error);
      
    })
  }
  loadDoctors(){
    this.staffService.getStaff(1,10,'').subscribe(doctorsResponse=>{
      this.doctors=doctorsResponse.result;
     
    },error=>{
     // console.log(error);
      
    })
  }
  
  pageChanged(event){
    this.pageNumber=event.page;
    this.getTasks();
  }

}
