<div class="container">
    <div class="d-flex justify-content-center">

        <img src = "assets/molifelogo.png"  width="300" height="auto">

   
    </div>
     <hr>
        <div class="d-flex justify-content-center">

            <h3>Mo-Life Portal</h3>
    
        </div>  
        <div class="card">
            <div class="card-body">
                <h1 class="card-title">Reset Password</h1>

                <div *ngIf="showError" class="alert alert-danger" role="alert">
                    <span [innerHTML]="errorMessage"></span>
                </div>

                <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                    Your password has been reset. Please <a [routerLink]="['/login']"> click here to log in. </a>
                </div>

                <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate (ngSubmit)="resetPassword(resetPasswordForm.value)">
                    <div class="form-group row">
                        <label for="password" class="col-form-label col-sm-2">Password:</label>
                        <div class="col-md-5">
                            <input type="password" id="password" formControlName="password" class="form-control" />
                        </div>
                        <div class="col-md-5">
                            <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is required</em>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="confirm" class="col-form-label col-sm-2">Confirm Password:</label>
                        <div class="col-md-5">
                            <input type="password" id="confirm" formControlName="confirm" class="form-control" />
                        </div>
                        <div class="col-md-5">
                            <em *ngIf="validateControl('confirm') && hasError('confirm', 'required')">Confirmation is required</em>
                            <em *ngIf="hasError('confirm', 'mustMatch')">Passwords must match</em>
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-1">          
                            <button type="submit" class="btn btn-info" [disabled]="!resetPasswordForm.valid">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>


</div>
