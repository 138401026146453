import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.css']
})
export class DesignationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
