import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/iuser';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  title = 'mo-life  clientApp';
  designations:any;
  //striuname:string=this.
  currentUser:IUser;

  /**
   *
   */
  constructor( private http:HttpClient, public authenticationService:AuthenticationService) { }
  ngOnInit(){
    
 //this.getUserName();
  }
  /*getUserName(){
    this.currentUser=this.authenticationService.getCurrentUser();
    if(this.authenticationService.loggedIn()){
      //console.log(this.currentUser.username + this.currentUser.role);
      return this.currentUser.userName;
    }
    
    }*/
    isLoggedIn(){
      this.authenticationService.loggedIn();
    }

    //console.log(this.designations);
  }

 
