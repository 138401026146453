<div class="row container mt-5">

    <main class="col-12">
      <div class="heading"> 
        <h2>Users</h2>
      </div>
      <a class="btn btn-primary mt-3" routerLink='/new-user' > Add User</a>
     
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th>Username</th>
                <th>Fullname</th>
                <th>Phone</th>
                <th>emailAddress</th>
                <th>Role</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let user of users"  >
                  <td>{{user.userName}}</td>
                  <td>{{user.firstName +" "+ user.lastName}} </td>
                  <td>{{user.phoneNumber}}</td>
                  <td>{{user.email}}</td>
                  <section *ngFor="let role of user.roles">
                   
                      <td  *ngIf="role.isActive; else elseBlock">{{role.name }}</td>
                      <ng-template #elseBlock></ng-template>
                   
                    
                 
                    </section>
                  
                  <td *ngIf="isAdmin">
                    <div class="btn-group">
                    <section *ngFor="let role of user.roles">
                    <button *ngIf="role.name!='SurgeryAdmin' && role.isActive  && !authenticationService.isSuperAdmin() "  type="button" class="btn btn-primary btn-sm genAdmin" 
                     (click)='openModalUpdateUser(updateUserTemplate,user)'>Edit</button> 
                     
                     <button *ngIf=" role.isActive && authenticationService.isSuperAdmin()   "  type="button" class="btn btn-primary btn-sm superAd" 
                     (click)='openModalUpdateUser(updateUserTemplate,user)'>Edit</button> 
                  </section>
                    <button *ngIf="authenticationService.isSuperAdmin()" type="button" class="btn btn-danger btn-sm" (click)="deleteUser(user)">Delete</button>
                  </div>
                  </td>
                
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-center" *ngIf="pagination">
          <pagination [boundaryLinks]="true"
           [totalItems]="pagination.totalCount"
           [itemsPerPage]="pagination.pageSize"
           [(ngModel)]="pagination.currentPage"
           (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>
      </div>

      <ng-template #updateUserTemplate bsModal  class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-sizes-name1" > <!--Start of Update Appointment-->
<div class="modal-header">
  <h4 class="modal-title pull-left">Update User</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
 
<form [formGroup]="userUpdateForm" (ngSubmit)="updateUser()" autocomplete="off">
  <div class="row">
                
    <div class="col-4">
        <h4> First Name: </h4>
        <input type="text" class="form-control" name="firstName" formControlName="firstName" >
    </div>
    <div class="col-4">
        <h4> Last Name: </h4>
        <input type="text" class="form-control" name="lastName" formControlName="lastName" >
    </div>
    <div class="col-4">
        <h4> Username: </h4>
        <input type="text" class="form-control" autocomplete="off" name="userName" formControlName="userName">
    </div>
  
</div>
<div class="row">
    <div class="col-6">
        <h4> Email: </h4>
        <input type="email" class="form-control" name="email" formControlName="email" >
    </div>
    <div class="col-6">
        <h4> Phone: </h4>
        <input type="text" class="form-control" name="phoneNumber" formControlName="phoneNumber">
    </div>
   
  
</div>
<div class="row">
    <div class="col-6">
        <h4> Password: </h4>
        <input type="password" class="form-control" name="password" autocomplete="off" formControlName="password" >
    </div>
    <div class="col-6">
        <h4> Confirm Password: </h4>
        <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" >
    </div>
  
</div>

<br>

  <div class="custom-control custom-checkbox">
      <div class="custom-control custom-checkbox" *ngFor="let data of roles; let j = index" formArrayName="roles">
        <div [formGroupName]="j">
          <input type="checkbox" formControlName="isActive" (change)="onLanguageChange(data?.name, $event.target.checked)"> {{data?.name}}<br>
        </div>
      </div>
  </div>
  <button  class="btn btn-primary mt-3"> Update</button>
</form>


</div>
</ng-template> <!--ENDAppointment UPDATE-->
    </main>
    </div>
