import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../interfaces/pagination';
import { PatientRecord } from '../interfaces/patient-record';

@Injectable({
  providedIn: 'root'
})
export class PatientRecordService {

  baseurl:string= environment.baseUrl;
patient:PatientRecord;
paginatedResult:PaginatedResult<PatientRecord[]>= new PaginatedResult<PatientRecord[]>();
  
  constructor(private http:HttpClient) { }
  getPatientRecords(patientId:number,pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<PatientRecord[]>(this.baseurl +'patient-records/patientId/'+patientId,{observe:'response',params}).pipe(
      map(response=>{
        this.paginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
       // console.log(JSON.parse(response.headers.get('pagination')));
        return this.paginatedResult;
      })
    )
  }

  getPatientRecordById(patientRecordId:number){
    return this.http.get<PatientRecord>(this.baseurl + 'patient-records/'+patientRecordId )
  }

  createPatientRecord( patientId:number,patientRecord:any){
    return this.http.post<PatientRecord>(this.baseurl + 'patient-records/'+patientId, patientRecord);
    
  }

  updatePatientRecord(patientRecordID:number,patientRecord:any){
    return this.http.put(this.baseurl+'patient-records/'+patientRecordID, patientRecord  );
  }

  
}
