import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/interfaces/iuser';
import { Pagination } from 'src/app/interfaces/pagination';
import { RoleDto } from 'src/app/interfaces/role-dto';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { findIndex } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public users: IUser[];
  pagination:Pagination;
  pageNumber?:number=1;
  pageSize?:number=25;
  searchTerm?:string=null;
  user:IUser;
  selectedUser?:IUser;
  userUpdateForm:FormGroup;
  
  
  arr2:any=[];
  
  roles : RoleDto[]=[];
  //exampleForm: FormGroup;
  isAdmin:boolean;

  constructor(public  authenticationService: AuthenticationService, private modalService:BsModalService,private toastService:ToastrService,private fb: FormBuilder) { 
 
  }

  ngOnInit(): void {
    this.getUsers();
   

    this.getData();
    this.initialiseUserUpdateForm();
    this.isAdmin= this.authenticationService.isAdmin();
    
  
  }
  modalRef: BsModalRef;

  openModal(templateCreateAppointment: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateCreateAppointment);
    
  }
 hideModal(){
  this.modalRef.hide();
  //this.createAppointmentForm.reset();
 
 }

  openModalUpdateUser(updateUserTemplate: TemplateRef<any>,user:IUser) {


    this.selectedUser=user;
    this.roles=this.selectedUser.roles;
     this.userUpdateForm.patchValue(
       {'firstName': this.selectedUser.firstName,
     'lastName':this.selectedUser.lastName,
     'userName':this.selectedUser.userName,
     'email':this.selectedUser.email,
     'phoneNumber':this.selectedUser.phoneNumber,
     'roles':this.roles
     
    })
     

     
    this.modalRef = this.modalService.show(updateUserTemplate,{class: 'modal-lg'});
    console.log(this.selectedUser);
  
  }
  initialiseUserUpdateForm(){

    let rolesFGs = this.roles.map(x => {
      return this.fb.group({
        
        name: x.name,
        isActive: x.isActive
      });
    });
  
    this.userUpdateForm= new FormGroup({
      firstName:new FormControl(),
      lastName: new FormControl(),
      userName: new FormControl(),
      password: new FormControl(),
      confirmPassword: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      roles:  this.fb.array(rolesFGs)
    })
  }
/*get rolesFormArray(){
  return this.userRegisterForm.get('roles') as FormArray;
}
addRolesCheckboxes(){
  this.myRoles.forEach(() => this.rolesFormArray.push(new FormControl(false)));
}*/
submit(){
  
}
  updateUser() {
   this.authenticationService.updateUser(this.selectedUser.id,this.userUpdateForm.value).subscribe(response=>{
     console.log(response);
     this.toastService.success("Success","User Successfully updated");
     setTimeout(()=>{
      window.location.reload();
    },3000);
   },error=>{
     this.toastService.error(error);
   });
  }
  onCheckboxChecked(event) {
    const checkArray:FormArray=this.userUpdateForm.get('roles') as FormArray;
   
    if(event.target.checked){
      checkArray.push(new FormControl(event.target.value))
    }else{
      let i:number=0;
      checkArray.controls.forEach((item:FormControl)=>{
        if(item.value==event.target.value){
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }
  deleteUser(user:IUser){
    this.authenticationService.deleteUser(user.id).subscribe(response=>{
      this.toastService.success("Success","User Successfully deleted");
      this.users.splice(this.users.indexOf(user),1);
    },error=>{
      this.toastService.error(error)
    });
  }

  getUsers(){
    this.authenticationService.getUsers()
    .subscribe(response=>{
      this.users=response;
     //console.log(this.users);
    
    },error=>{
      //console.log(error);
      
    })
  }

  pageChanged(event){
    this.pageNumber=event.page;
    //this.loadStaff();
  }

  //name = 'Angular';


  

  getData(){
    
    this.roles= [
      {
        "name": "Doctor",
        "isActive": false
      },
      {
        "name": "Assistant",
        "isActive": false
      },
      {
        "name": "SurgeryAdmin",
        "isActive": false
      },
      {
        "name": "Nurse",
        "isActive": false
      },
      {
        "name": "Receptionist",
        "isActive": false
      }
  ]
  
  }

 

   onLanguageChange(language: any, isChecked: boolean){
     //console.log((this.exampleForm.get('languages') as FormArray).getRawValue());
    // console.log('test');

   }

}
