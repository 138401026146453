<div class="row container-fluid " >
    
    <main class="col-12 main-row">
      <div class="heading"> 
        <h2>Payment Records </h2>
      </div>

      <div class="table-responsive">
    
               
        <hr>
        <button type="button" class="btn btn-primary" (click)="openModal(templateCreatePayment)">Enter Payment</button>
        <br>
    
        <table class="table table-striped table-sm" *ngIf="payments?.length>0; else elseBlock">
          <thead>
            <div><i class="fa fa-money mt-3 mb-3 pr-2 "  style="font-size: 20px;" aria-hidden="true"></i><Span class="span-heading" style="font-size: 20px;">Payments</Span></div>
            <tr>
              <th>Payment Date</th>
              <th>Invoice Date</th>
              <th>Invoice ID</th>
              <th>Client</th>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payment of payments" routerLink='/patients/{{payment.invoice.patientID}}'>
              <td>{{payment.date | date}}</td>
              <td>{{payment.invoice.dateCreated | date}}</td>
              <td>{{payment.invoice.invoiceID}}</td>
              <td>{{payment.invoice.patient.firstName}}</td>
              <td>{{payment.amountPaid}}</td>
              <td>{{payment.paymentMethod}}</td>
              <td>{{payment.note}}</td>
              <td><button type="button" class="btn btn-primary"  >View</button></td>
          
    
            </tr>
          </tbody>
        </table>
        <ng-template #elseBlock>
          <div class="alert alert-info" role="alert">
           Oops!! No records found.
          </div>

        </ng-template>
      </div><!--end of payment lists-->
      <div class="d-flex justify-content-center" *ngIf="paginationPayment"> <!--Pagination payment-->
        <pagination [boundaryLinks]="true"
         [totalItems]="paginationPayment.totalCount"
         [itemsPerPage]="paginationPayment.pageSize"
         [(ngModel)]="paginationPayment.currentPage"
         (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
        </pagination>
      </div>
    
    
      <ng-template #templateCreatePayment>
        <div class="modal-header">
          <h4 class="modal-title pull-left">New Payment</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-info" *ngIf="createPaymentForm.touched">
            <p><strong>Information: </strong> Changes detected, please not any unsaved changes will be lost!</p>
        </div>
          <form class =" " [formGroup]="createPaymentForm"  (ngSubmit)='newPayment()' autocomplete="off"> 
            <div class="mb-3" >
                <label  class="form-label"> Invoice </label>
               <span></span>
                <select id="myinvoice" class="form-control" formControlName='invoiceID' >
                    <option   *ngFor="let invoice of invoices" [ngValue]="invoice.invoiceID">
                        {{'invoice Id '+invoice.invoiceID +',  Patient: '+ invoice.patient.firstName + ', Invoice Balance: '+ invoice.balance}}
                      
                    </option>
              
                  </select>
              </div>             
            <div class="mb-3">
                <label for="date" class="form-label">Payment Date</label>
                <input formControlName='date'
                type="date" class="form-control" placeholder="" >
                
              </div>
              <div class="mb-3">
                <label  class="form-label">Amount</label>
                <input formControlName='amountPaid' type="text" class="form-control">
              </div> 
              <div class="mb-3">
                <label  class="form-label">Paymnet Method</label>
                <input formControlName='paymentMethod' type="text" class="form-control">
              </div> 
              <div class="mb-3">
                <label  class="form-label">Note</label>
                <input formControlName='note' type="text" class="form-control">
              </div> 
             
        
            <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
        
        </form>
        <!--p> {{createPaymentForm.value | json}}</p-->
        </div>
      </ng-template> <!--End of Create payment template-->
  

    </main>
</div>
