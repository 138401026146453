  import { Component, Input, OnInit, TemplateRef } from '@angular/core';
  import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
  import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
  import { ToastrService } from 'ngx-toastr';
import { Invoice } from 'src/app/interfaces/invoice';
import { Patient } from 'src/app/interfaces/patient';

  import { Person } from 'src/app/interfaces/person';
import { InvoiceService } from 'src/app/_services/invoice.service';
  import { PatientService } from 'src/app/_services/patient.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { Pagination } from 'src/app/interfaces/pagination';
import { AuthenticationService } from 'src/app/_services/authentication.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

  @Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css']
  })
  export class InvoicesComponent implements OnInit {
  @Input() patient:Patient;
  invoices:Invoice[];
  pageNumber?:number=1;
  pageSize?:number=5;
  searchTerm?:string=null;
  public paginationInvoice:Pagination;
  selectedInvoice?:Invoice;
  patientData:Patient;
  createInvoiceForm:FormGroup;
  invoiceItems:FormArray;

  modalRef: BsModalRef;


  
    openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template,{class: 'modal-lg'});
    }
  hideModal(){
    this.createInvoiceForm.reset();
    this.modalRef.hide();
    while(this.invoiceItems.length !==0){
      this.invoiceItems.removeAt(0);
    }
  }
    openModalInvice(updateInvoiceTemplate: TemplateRef<any>,invoice:Invoice) {
      this.modalRef = this.modalService.show(updateInvoiceTemplate,{class: 'modal-lg'});
      this.selectedInvoice=invoice;
      this.invoiceItems= this.createInvoiceForm.get('invoiceItems')as FormArray;
      this.selectedInvoice.invoiceItems.forEach((x)=>{
       // this.invoiceItems.push(this.formBuilder.group())
       this.invoiceItems.push(
        new FormGroup({
          item:new FormControl(x.item),
          icd_10_Code:new FormControl(x.icd_10_Code),
          description:new FormControl(x.description),
          quantity:new FormControl(x.quantity),
          unityPrice:new FormControl(x.unityPrice)
  
        })
       )
      }
      );
      
    }

    constructor(public  authenticationService: AuthenticationService,private patientService:PatientService, private formBuilder:FormBuilder, 
      private modalService:BsModalService, private toastService:ToastrService,
      private invoiceService:InvoiceService,private datePipe:DatePipe) { }

    ngOnInit(): void {

      this.loadPatient();
      //console.log(this.patient.lastName)
    this.initializeCreatInvoiceForm();
    this.invoices=this.patient.invoices;
    
      
    }

    getInvoices(){this.invoiceService.getInvoices(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
      this.invoices=response.result;
      this.paginationInvoice=response.pagination;
    },error=>{
      this.toastService.error(error);
      
    })
  
    }
    

    initializeCreatInvoiceForm(){
      this.createInvoiceForm= new FormGroup(
        {
          dueDate:new FormControl(),
          amountPaid:new FormControl(),
         
          invoiceTerms: new FormControl(),
          
          invoiceItems:this.formBuilder.array([])
        }
      )
    }
    removeItem(index:number){
      this.invoiceItems.removeAt(index);
    }
    createInvoiceItem(){
      return  new FormGroup({
        item:new FormControl(),
        icd_10_Code:new FormControl(),
        description:new FormControl(),
        quantity:new FormControl(),
        unityPrice:new FormControl()

      });
    }
    addItem(){
      this.invoiceItems= this.createInvoiceForm.get('invoiceItems')as FormArray;
      this.invoiceItems.push(this.createInvoiceItem());
    }
    newInvoice(){
      this.invoiceService.createInvoice(this.patient.patientID,
        this.createInvoiceForm.value).subscribe(invoiceResponse=>{
          
          this.createInvoiceForm.reset();
          setTimeout(()=>{
        window.location.reload();
          },3000);
          this.toastService.success('Your Invoice been successfully created.', 'Success');
         
        },error=>{
          this.toastService.error(error.error);
          
        });
    }
    updateInvoice(){
      this.invoiceService.updateInvoice(
        this.selectedInvoice.invoiceID,this.createInvoiceForm.value).subscribe(()=>{
          this.toastService.success('Invoice Updated successfully','Invoice Update');
          this.createInvoiceForm.reset();
           setTimeout(()=>{
         window.location.reload();
           },3000);
          
        },error=>{
          this.toastService.error(error.error);
          
        });
    }
    loadPatient(){
      /*this.patientService.getPatientById(this.patient.personID).subscribe(patientResponse=>
        {this.invoices=patientResponse.invoices;
        this.patientData=patientResponse;
      // console.log(this.patientData.patientID)
      } )*/
    }
    pageChanged(event){
      this.pageNumber=event.page;
      this.getInvoices();
    }

    /*invoiceItemsPDF(){
      let rows:any[]=[];
      rows.push(['TARIFF CODE','DETAILS','QTY','AMOUNT', 'TOTAL']);
      var i;
      for(let item of this.selectedInvoice.invoiceItems){
        rows.push(
          [
            item.item,item.description,item.quantity,item.unityPrice,item.total
          ]
        )
      }
      return rows;

    }*/
    invoiceItemsPDF(){
      let rows:any[]=[];
      rows.push(['TARIFF CODE', 'ICD 10 CODE','DETAILS','QTY','AMOUNT', 'TOTAL']);
      var i;
      for(let item of this.selectedInvoice.invoiceItems){
        rows.push(
          [
            item.item,item.icd_10_Code,item.description,item.quantity,item.unityPrice,item.total
          ]
        )
      }
      return rows;

    }




    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = error => {
          reject(error);
        };
        img.src = url;
      });
    }


    async generatePdf(invoice:Invoice){
      this.selectedInvoice=invoice;
      const documentDefinition = {
      content: [
  
        //logo
        {
          image: await this.getBase64ImageFromURL(
            "assets/molifelogo.png"
          ),width:100,
          alignment : 'center'
         },
     
     
      {
        style:'hr',
        columns: [
          {
            width: 150,
            style:'mr',
            
            text: [{text :'INVOICE: '+ this.selectedInvoice.invoiceID+ '\n'},
          {text: 'Date: '+ this.datePipe.transform(this.selectedInvoice.dateCreated) + '\n'} ,
          {text:'Due : '+ this.datePipe.transform(this.selectedInvoice.dueDate) + '\n'},
          {text:'Status : '+ this.selectedInvoice.status+ '\n'}
          ]
            
          },
          [
            //Doctor Details
            'Dr Mo Life General Medical Practice',
            { 
              columns: [
                {text: [{text:'Email Address: ',style:'bold'},
                      {text:'doctor@mo-life.co.za'}]}, 
              {text: [{text:'Email Address: ',style:'bold'},
                      {text:'doctor@mo-life.co.za'}]}, 
              {text: [{text:'Phone: ',style:'bold'},
                      {text:'011897546'}]}, 
                    ]
            }
          ]
        ]
      },
      //#### Table personal Details#####
      {
        style:'table',
        table:{
          body:[
            [{text:'Patient Details',bold:true,alignment:'center',colSpan:3},{},{}],

            [{text:[{text:'Full Name: ',style:'bold'},this.patient.firstName+' '+this.patient.lastName]},
          {text:[{text:'Title: ',style:'bold'},this.patient.title]}, 
          {text:[{text:'D.O.B: ',style:'bold'},this.datePipe.transform(this.patient.dateOfBirth,'yyyy-MM-dd')]}],


          [{text:[{text:'Gender: ',style:'bold'},this.patient.gender]},
          {text:[{text:'ID No: ',style:'bold'},this.patient.nationalIdentityNumber]},
           {text:[{text:'Passport Number: ',style:'bold'},this.patient.passportNumber]}],
          [{text:[{text:'Employer: ',style:'bold'},this.patient.employer]},
          {text:[{text:'Occupation: ',style:'bold'},this.patient.occupation]},
          {text:[{text:'Phone Number: ',style:'bold'},this.patient.phone]}],
  
          [{text:[{text:'Email: ',style:'bold'}, this.patient.emailAddress],colSpan:2},{},{}],
          [{text:[{text:'Address: ',style:'bold'}, this.patient.physicalAddress.unitNumber + ' '
          +this.patient.physicalAddress.unitName + ', '+this.patient.physicalAddress.streetAddress
        +', '+this.patient.physicalAddress.suburb +', '+this.patient.physicalAddress.province +', '
      +this.patient.physicalAddress.country +','+this.patient.physicalAddress.postalCode ],colSpan:3}],
  
        //#### Table Medical Details#####
      [{text:'Medical Aid Details',style:'header2',alignment:'center',colSpan:3},{},{}],
      [{text:[{text:'Provider: ',style:'bold'},this.patient.medicalAid.medicalAidName],colSpan:2},
      {},{text:[{text:'Medical Aid No : ',style:'bold'},this.patient.medicalAid.medicalAidNumber]}],


      [{text:[{text:'Main Member: ',style:'bold'},this.patient.medicalAid.medicalAidMainMember==true?'YES':'NO']},{text:[{text:'Dependent Code: ',style:'bold'}],colSpan:2},{}],
      
      [{text:[{text:'Main Member Details : ',style:'bold'}],colSpan:3},{},{}],
  
      [{text:[{text:'Holder Name : ',style:'bold'},this.patient.medicalAid.medicalAidMainMemberFullName]},
      {text:[{text:'Holder ID No: ',style:'bold'},this.patient.medicalAid.medicalAindMainMemberIdentityNumber]},
      {text:[{text:'Holder Occupation: ',style:'bold'},this.patient.medicalAid.occupation]}],
  
      [{text:[{text:'Phone Number : ',style:'bold'}, this.patient.medicalAid.phoneNumber],colSpan:2},
      {text:[{text:'Employer : ',style:'bold'}, this.patient.medicalAid.employer==null?'NA':this.patient.medicalAid.employer]},{}],
      [{text:[{text:'Address: ',style:'bold'}, this.patient.medicalAid.residentaillAddress ],colSpan:3}],
  
            //#### Invoice Details#####
      [{text:'Invoice Details',style:'header2',alignment:'center',colSpan:3},{},{}],
       [
							{
								table: {
                  widths: ['*','*', 100, '*', '*', '*'],
									body: this.invoiceItemsPDF(),
                  
								},	layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 !== 0&& rowIndex!==0) ? '#CCCCCC' : null;
                  }
                },colSpan:3
							},{},{}
						],
      
            [{text:'Invoice Total',style:'header2',alignment:'right',colSpan:2},{},{text:this.selectedInvoice.invoiceTotal,style:'total',alignment:'center'}],
            [{text:'Total Paid',style:'header2',alignment:'right',colSpan:2},{},{text:this.selectedInvoice.amountPaid,style:'green',alignment:'center'}],
            [{text:'Total Amount Due',style:'header2',alignment:'right',colSpan:2},{},{text:this.selectedInvoice.invoiceTotal-this.selectedInvoice.amountPaid,style:this.selectedInvoice.status=='Paid'?'hTotal':'total',alignment:'center'}],
     
     
        ]
        }
      },
      {
        text:'Terms',style:'header1'
      },
      {
        text:'Banking Details and other details here.....'
      }
      
      
  
      ],
      styles:{
        header1:{
          fontSize:18,
          bold:true,
          marginBottom:10
        },
        header2:{
          fontSize:15,
          bold:true,
          marginTop:10
        },
        hr:{
          marginTop:10,
          borderTop:2,
          marginBottom:10

        },
        total:{
          fontSize:15,
          bold:true,
          marginTop:10,
          color:'red'
        },
        green:{
          fontSize:15,
          bold:true,
          marginTop:10,
          color:'green'
        },
        hTotal:{
          fontSize:15,
          bold:true,
          marginTop:10,
          color:'black'
        },
        mr:{
          marginRight:10
        },
        bold:{
          bold:true
        }
      }
    };
      pdfMake.createPdf(documentDefinition).open();
     }
    

  }
