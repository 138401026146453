
<div class="row container " >
  
    <main class="col-12">
        
        <div class="heading"> 
            <h2>Add New Staff Member</h2>
          </div>
        <h3>Personal Details</h3>

        <div class="alert alert-info" *ngIf="createStaffForm.dirty">
            <p><strong>Information: </strong> Changes detected, please not any unsaved changes will be lost!</p>
        </div>
        
        <form [formGroup]="createStaffForm"  (ngSubmit)="addNewStaff()" >
            <div class="row">
                
                <div class="col-4">
                    <h4> First Name: </h4>
                    <input type="text" class="form-control" name="firstName" formControlName="firstName" >
                </div>
                <div class="col-4">
                    <h4> Last Name: </h4>
                    <input type="text" class="form-control" name="lastName" formControlName="lastName" >
                </div>
                <div class="col-4">
                    <h4> Title: </h4>
                    <input type="text" class="form-control" name="title" formControlName="title">
                </div>
              
            </div>
            <div class="row">
                <div class="col-4">
                    <h4> Date Of Birth: </h4>
                    <input type="date" class="form-control" name="dateOfBirth" formControlName="dateOfBirth" >
                </div>
                <div class="col-4">
                    <h4> Gender: </h4>
                    <input type="text" class="form-control" name="gender" formControlName="gender">
                </div>
                <div class="col-4">
                    <h4> ID Number: </h4>
                    <input type="text" class="form-control" name="nationalIdentityNumber" formControlName="nationalIdentityNumber" >
                </div>
              
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <h4> Phone Number: </h4>
                    <input type="text" class="form-control" name="phone"  formControlName="phone">
                </div>
                <div class="col-4">
                    <h4> Email: </h4>
                    <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress">
                </div>
                <div class="col-4">
                    <h4> Position: </h4>
                    <input type="text" class="form-control" name="position" formControlName="position">
                </div>
                
              
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <h4> Passport Number: </h4>
                    <input type="text" class="form-control" name="passportNumber" formControlName="passportNumber" >
                </div>
                <div class="col-4">
                    <h4> Employer: </h4>
                    <input type="text" class="form-control" name="employer" formControlName="employer">
                </div>
                <div class="col-4">
                    <h4> Occupation: </h4>
                    <input type="text" class="form-control" name="occupation"  formControlName="occupation">
                </div>
              
            </div>
     
           
        <button  class="btn btn-primary mt-3"> Save Changes</button>
        
        </form>
    </main>
    </div>
