import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Appointment } from 'src/app/interfaces/appointment';
import { Pagination } from 'src/app/interfaces/pagination';
import { Patient } from 'src/app/interfaces/patient';
import { Staff } from 'src/app/interfaces/staff';
import { AdminService } from 'src/app/_services/admin.service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { PatientService } from 'src/app/_services/patient.service';
import { StaffService } from 'src/app/_services/staff.service';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit {
  appointments:Appointment[];
  pageNumber?:number=1;
  pageSize?:number=30;
  searchTerm?:string=null;
  public paginationAppointment:Pagination;
  createAppointmentForm:FormGroup;
  doctors:Staff[];
  patients:Patient[];
  appointment:Appointment;
 
  selectedAppointment?:Appointment;
 times:string[] = ["09:00", "09:30", "10:00", "10:30","11:00","11:30", "12:00", "12:30", "13:00", "13:30","14:00", "14:30","15:00","15:30"];
 
  
 modalRef: BsModalRef;

 openModal(templateCreateAppointment: TemplateRef<any>) {
   this.modalRef = this.modalService.show(templateCreateAppointment,{class: 'modal-lg'});
   
 }
hideModal(){
 this.modalRef.hide();
 this.createAppointmentForm.reset();

}
openModalUpdateTask(updateAppointmentTemplate: TemplateRef<any>,appointment:Appointment) {


  this.selectedAppointment=appointment;
   this.createAppointmentForm.patchValue({'date': this.datepipe.transform(this.selectedAppointment.date, 'yyyy-MM-dd'),
   'time':this.datepipe.transform(this.selectedAppointment.time, 'yyyy-MM-dd'),
   'description':this.selectedAppointment.description,
   'patientID':this.selectedAppointment.patientID,
   'assignedDoctor':this.selectedAppointment.assignedDoctor})
   
  this.modalRef = this.modalService.show(updateAppointmentTemplate,{class: 'modal-lg'});
 // console.log(this.selectedAppointment);

}

  constructor(private appointmentService:AppointmentService, private toastService:ToastrService,
    private modalService:BsModalService,public datepipe: DatePipe,
    private formBuilder:FormBuilder, private adminService:AdminService, private patientService:PatientService) { }

  ngOnInit(): void {
    this.getAppointments();
    this.loadDoctors();
    this.loadPatients();
    this.initializeTaskForm();
     
  }

  newAppointment(){
 
   this.appointmentService.createAppointment(this.createAppointmentForm.get('patientID').value, 
   this.createAppointmentForm.value).subscribe(response=>{
    this.appointment=response;
    this.toastService.success("Appointment Added Successfully","Success");
    this.createAppointmentForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },2000);
   }, error=>{
     this.toastService.error(error);
   })
   //console.log(this.createTaskForm.value)
  }
  updateAppointment(){
    this.appointmentService.updateAppointment(this.selectedAppointment.appointmentID,
      this.createAppointmentForm.value).subscribe(()=>{
        this.toastService.success('Appointment Updated successfully','Appointment Update');
        this.createAppointmentForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },3000);
      },error=>{
        this.toastService.error(error.error);
        
      });

  }

  initializeTaskForm(){
    this.createAppointmentForm= new FormGroup(
      {
       
    
        date:new FormControl(),
        time: new FormControl(),
        patientID:new FormControl(),
      
        assignedDoctor: new FormControl(),
        description: new FormControl()
    
  })
}

  getAppointments(){
    this.appointmentService.getAppointments(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
      this.appointments=response.result;
      this.paginationAppointment=response.pagination;
    },error=>{
      this.toastService.error(error);
      
    })
  }
  loadDoctors(){
    this.adminService.getDoctors('doctor').subscribe(doctorsResponse=>{
      this.doctors=doctorsResponse;
     
    },error=>{
      this.toastService.error(error);
      
    })
  }
  loadPatients(){
    this.patientService.getPatients(this.pageNumber,this.pageSize,this.searchTerm).subscribe(doctorsResponse=>{
      this.patients=doctorsResponse.result;
     
    },error=>{
      this.toastService.error(error);
      
    })
  }
  
  pageChanged(event){
    this.pageNumber=event.page;
    this.getAppointments();
  }


}
