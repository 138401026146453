import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../interfaces/pagination';
import { Person } from '../interfaces/person';

const httOptions= {
  headers:new HttpHeaders({
    "Content-Type": "application/json"
  })
}
@Injectable({
  providedIn: 'root'
})
export class PersonService {
  baseurl:string= environment.baseUrl;
  person:Person;
  paginatedResult:PaginatedResult<Person[]>= new PaginatedResult<Person[]>();
  


  constructor(private http:HttpClient) { }
  getPeople(personType:string, pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Person[]>(this.baseurl +'designations/'+personType+'/details',{observe:'response',params}).pipe(
      map(response=>{
        this.paginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        console.log(JSON.parse(response.headers.get('pagination')));
        return this.paginatedResult;
      })
    )
  }

 /* getPeople(personType:string){
   return  this.http.get<Person[]>(this.baseurl +'designations/'+personType+'/details',
     httOptions
  );
}*/

getPersonByID(personType:string,personId:number){
  return this.http.get<Person>(this.baseurl + 'designations/'+personType+'/details/'+personId)
}

updatePerson(personType:string,personId:number, person:Person){
return this.http.put(this.baseurl +'designations/' +personType+'/details/'+personId,person);
}

addPerson(person:any,personType:string):Observable<Person>{
  return this.http.post<Person>(this.baseurl + 'designations/'+ personType+'/details', person)
}
}
