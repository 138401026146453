import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';
import { PatientRecord } from 'src/app/interfaces/patient-record';
import { Person } from 'src/app/interfaces/person';
import { PatientRecordService } from 'src/app/_services/patient-record.service';
import { PatientService } from 'src/app/_services/patient.service';
import { PersonService } from 'src/app/_services/person.service';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.css']
})
export class PatientEditComponent implements OnInit {

  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.createPatientForm.dirty){
      $event.returnValue=true;
    }
  }

  createPatientForm:FormGroup;
  @Input() patient:Patient;
  
 
  initilizePatientForm(){
    this.createPatientForm= new FormGroup(
      {
       
        firstName:new FormControl(),
        lastName : new FormControl(),
        middleName:new FormControl(),
        maidenName: new FormControl(),
        title :new FormControl(), 
        maritalStatus:new FormControl(), 
        dateOfBirth:new FormControl(),
        gender :new FormControl(),
        nationalIdentityNumber:new FormControl(),
        passportNumber:new FormControl(),
        phone:new FormControl(),
        emailAddress: new FormControl(),
        occupation:new FormControl(),
        employer:new FormControl(),
      
        
        physicalAddress:new FormGroup({
          unitNumber:new FormControl(),
          unitName : new FormControl(),
          streetAddress : new FormControl(),
          addressLineTwo :new FormControl(),
          postalCode : new FormControl(),
          suburb : new FormControl(),
          province :new FormControl(),
          country : new FormControl()
        })
       ,
        medicalAid:new FormGroup({
          medicalAidName  : new FormControl(),
          medicalAidNumber  : new FormControl(),
          medicalAidMainMember  : new FormControl(),
          medicalAidMainMemberFullName  : new FormControl(),
          medicalAindMainMemberIdentityNumber  : new FormControl(),
          dependentCode:new FormControl(),
          occupation  : new FormControl(),
          postalAddress  :new FormControl(),
          employer  : new FormControl(),
          residentaillAddress  :new FormControl(),
         
          phoneNumber  : new FormControl()
        }),
        patientNextOfKin: new FormGroup({
          fullName: new FormControl(),
          emailAddress: new FormControl(),
          phoneNumber:new FormControl(),
          fullAddress:new FormControl(),
          relationship: new FormControl()

        })
        ,
    patientRefDoctor:  new FormGroup({
      name:new FormControl(),
      fullName:new FormControl(),
      practiceNumber:new FormControl(),
      emailAddress:new FormControl(),
      fullAddress:new FormControl()
        })
      }
    )}

    setPatientFormValue(){
      this.createPatientForm.patchValue({
        'firstName':this.patient.firstName,
        'lastName':this.patient.lastName,
        'title':this.patient.title,
        middleName:this.patient.middleName,
        maidenName:this.patient.maidenName,
        'dateOfBirth':this.patient.dateOfBirth,
        gender :this.patient.gender,
        'nationalIdentityNumber':this.patient.nationalIdentityNumber,
        'passportNumber':this.patient.passportNumber,
        'phone':this.patient.phone,
        'emailAddress': this.patient.emailAddress,
        'occupation':this.patient.occupation,
        'employer':this.patient.employer,
        'physicalAddress':{
          'unitNumber':this.patient.physicalAddress.unitNumber,
          'unitName':this.patient.physicalAddress.unitName,
          streetAddress : this.patient.physicalAddress.streetAddress,
          addressLineTwo :this.patient.physicalAddress.addressLineTwo,
          postalCode : this.patient.physicalAddress.postalCode,
          suburb : this.patient.physicalAddress.suburb,
          province :this.patient.physicalAddress.province,
          country : this.patient.physicalAddress.country
        },
        medicalAid:{
          medicalAidName  : this.patient.medicalAid.medicalAidName,
          medicalAidNumber  : this.patient.medicalAid.medicalAidNumber,
          medicalAidMainMember  : this.patient.medicalAid.medicalAidMainMember,
          medicalAidMainMemberFullName  : this.patient.medicalAid.medicalAidMainMemberFullName,
          medicalAindMainMemberIdentityNumber  : this.patient.medicalAid.medicalAindMainMemberIdentityNumber,
          occupation  : this.patient.medicalAid.occupation,
          postalAddress  :this.patient.medicalAid.postalAddress,
          employer  : this.patient.medicalAid.employer,
          residentaillAddress  :this.patient.medicalAid.residentaillAddress,
          phoneNumber  : this.patient.medicalAid.phoneNumber,
          dependentCode:this.patient.medicalAid.dependentCode
        },
        patientNextOfKin:{
          fullName: this.patient.patientNextOfKin.fullName,
          emailAddress:  this.patient.patientNextOfKin.emailAddress,
          phoneNumber: this.patient.patientNextOfKin.phoneNumber,
          fullAddress: this.patient.patientNextOfKin.fullAddress,
          relationship:  this.patient.patientNextOfKin.relationship
        },
        patientRefDoctor:{
          name:this.patient.patientRefDoctor.name,
          fullName:this.patient.patientRefDoctor.fullName,
          practiceNumber:this.patient.patientRefDoctor.practiceNumber,
          emailAddress:this.patient.patientRefDoctor.emailAddress,
          fullAddress:this.patient.patientRefDoctor.fullAddress
        }
      })

    }

  constructor(private router:Router, private patientService:PatientService,private toastService:ToastrService) { }

  ngOnInit(): void {
    this.initilizePatientForm();
    this.setPatientFormValue();
  }
  updatePatient(){
   this.patientService.updatePatient(this.patient.patientID,this.createPatientForm.value).subscribe(()=>{
    this.toastService.success('Your Patient been successfully updated.', 'Success');
    this.createPatientForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },3000);
    
    console.log(this.createPatientForm.dirty);
    },error=>{
      this.toastService.error(error);
      
   })
  // console.log(this.createPatientForm.value);
  }
  
  

}
//console.log(this.patientEdit.value);