import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Staff } from 'src/app/interfaces/staff';
import { StaffService } from 'src/app/_services/staff.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-staff-detail',
  templateUrl: './staff-detail.component.html',
  styleUrls: ['./staff-detail.component.css']
})
export class StaffDetailComponent implements OnInit {
  role=2;
  generatePdf(){
    const documentDefinition = {
    content: [{text: 'Mo-Life Surgery : ',style:'header1'},
     //heading
     {text:'Staff File # '+ this.staff.staffID,marginBottom:10},

    //#### Table personal Details#####
    {
      style:'table',
      table:{
        body:[
          [{text:'Personal Details',bold:true,alignment:'center',colSpan:3},{},{}],
          [{text:[{text:'Full Name: ',style:'bold'},this.staff.firstName+' '+this.staff.lastName]},
        {text:[{text:'Title: ',style:'bold'},this.staff.title]}, 
        {text:[{text:'D.O.B: ',style:'bold'},this.datePipe.transform(this.staff.dateOfBirth,'yyyy-MM-dd')]}],
        [{text:[{text:'Gender: ',style:'bold'},this.staff.gender]},
        {text:[{text:'ID No: ',style:'bold'},this.staff.nationalIdentityNumber]},
         {text:[{text:'Passport Number: ',style:'bold'},this.staff.passportNumber]}],
        
        [{text:[{text:'Employer: ',style:'bold'},this.staff.employer]},
        {text:[{text:'Occupation: ',style:'bold'},this.staff.occupation]},
        {text:[{text:'Practice Number: ',style:'bold'},this.staff.practiceNumber]}],

        [{text:[{text:'Email: ',style:'bold'}, this.staff.emailAddress],colSpan:2},
        {text:[{text:'Phone Number: ',style:'bold'},this.staff.phone]},
        {text:[{text:'Position: ',style:'bold'},this.staff.position]}
        ],
      ]
      }
    },
   
    ],
    styles:{
      header1:{
        fontSize:18,
        bold:true,
        marginBottom:10
      },
      header2:{
        fontSize:15,
        bold:true,
        marginTop:10
      },
      bold:{
        bold:true
      }
    }
  };
    pdfMake.createPdf(documentDefinition).open();
   }

public staff:Staff;

public staffInvoiceRecord:any;
  constructor(private staffService:StaffService, private route:ActivatedRoute, 
    private toastService:ToastrService, private datePipe:DatePipe) { }
  

  ngOnInit(): void {
    this.loadstaff();
    //console.log(this.staffRecord + 'detail test');
  
  }
  loadstaff(){
    this.staffService.getStaffByID(+this.route.snapshot.paramMap.get('id')).subscribe(response=>{
      this.staff=response;
      
    },error=>{
      this.toastService.error(error.error);
    });
  }
}
