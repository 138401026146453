import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/interfaces/pagination';
import { Person } from 'src/app/interfaces/person';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PersonService } from 'src/app/_services/person.service';
const doctor:string='doctor';
@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})

export class DoctorListComponent implements OnInit {
  public doctors: Person[];
  pagination:Pagination;
  pageNumber?:number=1;
  pageSize?:number=20;
  searchTerm?:string=null;

  constructor(private  authenticationService: AuthenticationService, private personService:PersonService) { }

  ngOnInit(): void {
    this.loadDoctors();
  }


  loadDoctors(){
    this.personService.getPeople(doctor,this.pageNumber,this.pageSize,this.searchTerm).subscribe(doctorsResponse=>{
      this.doctors=doctorsResponse.result;
      this.pagination=doctorsResponse.pagination;
    },error=>{
      console.log(error);
      
    })
  }

  pageChanged(event){
    this.pageNumber=event.page;
    this.loadDoctors();
  }

}
