import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EChartsOption } from 'echarts';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DashboardStats } from 'src/app/interfaces/dashboard-stats';
import { Invoice } from 'src/app/interfaces/invoice';
import { Mytask } from 'src/app/interfaces/mytask';
import { Pagination } from 'src/app/interfaces/pagination';
import { Patient } from 'src/app/interfaces/patient';

import { AdminService } from 'src/app/_services/admin.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { PatientService } from 'src/app/_services/patient.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  invoices:Invoice[];
  tasks:Mytask[];
  pageNumber?:number=1;
  pageSize?:number=5;
  searchTerm?:string=null;
  public paginationInvoice:Pagination;
  public paginationTasks:Pagination;
  selectedInvoice?:Invoice;
  patientData:Patient;
  dashStats:DashboardStats=null;
  createInvoiceForm:FormGroup;
  invoiceItems:FormArray;

  modalRef: BsModalRef;

    
  
    openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template);
    }
  hideModal(){
    this.modalRef.hide()
    while(this.invoiceItems.length !==0){
      this.invoiceItems.removeAt(0);
    }
  }
    openModalInvice(updateInvoiceTemplate: TemplateRef<any>,invoice:Invoice) {
      this.modalRef = this.modalService.show(updateInvoiceTemplate,{class: 'modal-lg'});
      this.selectedInvoice=invoice;
      this.invoiceItems= this.createInvoiceForm.get('invoiceItems')as FormArray;
      this.selectedInvoice.invoiceItems.forEach((x)=>{
        this.invoiceItems.push(this.formBuilder.group(x))
      }
      );
      
    }

  constructor(public authenticationService:AuthenticationService,
     private adminService:AdminService, private toastService:ToastrService, private formBuilder:FormBuilder, 
     private modalService:BsModalService,private patientService:PatientService,private invoiceService:InvoiceService) { }

  ngOnInit(): void {
    this.getInvoices();
    this.getTasks();
    //console.log(this.tasks);
    this.getDashStats();
  }
  getInvoices(){this.invoiceService.getInvoices(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
    this.invoices=response.result;
    this.paginationInvoice=response.pagination;
  },error=>{
    this.toastService.error(error);
    
  })

  }
  getTasks(){
    this.adminService.getTasks(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
      this.tasks=response.result;
      this.paginationTasks=response.pagination;
    },error=>{
      this.toastService.error(error);
      
    })
  }
  getDashStats(){
    this.adminService.getDashboardStats().subscribe(response=>{
      this.dashStats=response;
      //console.log(this.dashStats);
    }, error=>{
      this.toastService.error(error);
    });
    
  }
  pageChanged(event){
    this.pageNumber=event.page;
    this.getInvoices();
  }

  initializeCreatInvoiceForm(){
    this.createInvoiceForm= new FormGroup(
      {
        dueDate:new FormControl(),
        amountPaid:new FormControl(),
        invoiceItems:this.formBuilder.array([])
      }
    )
  }
  removeItem(index:number){
    this.invoiceItems.removeAt(index);
  }
  createInvoiceItem(){
    return  new FormGroup({
      item:new FormControl(),
      description:new FormControl(),
      quantity:new FormControl(),
      unityPrice:new FormControl()
    });
  }
  addItem(){
    this.invoiceItems= this.createInvoiceForm.get('invoiceItems')as FormArray;
    this.invoiceItems.push(this.createInvoiceItem());
  }
    
  }


