import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { use } from 'echarts';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ForgotPassword } from '../interfaces/forgot-password';
import { IUser } from '../interfaces/iuser';
import { JwtUser } from '../interfaces/jwt-user';
import { ResetPassword } from '../interfaces/reset-password';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl:string= environment.baseUrl;

  helper = new JwtHelperService();

  currentUser:IUser={
    id:null,
    userName:null,
    email:null,
    roles:[],
    firstName:null,
    password:null,
    confirmPassword: null,
    phoneNumber:null,
    lastName:null
  }
  sUser= new BehaviorSubject<JwtUser>(null);
  
  currentUser$=this.sUser.asObservable();//$to show it is an observable
  
  constructor(private http:HttpClient,private router:Router) { }
  /*login(model:any){
   return this.http.post(this.baseUrl + 'authentication/login', model).pipe(
     map((response:any)=>{
       const decodedToken= this.helper.decodeToken(response.accessToken);
       
       this.currentUser.email = decodedToken.email;
        
        this.currentUser.roles[0] = decodedToken.role;
        this.currentUser.userName=decodedToken.given_name;
        localStorage.setItem('mytoken', response.accessToken);
        localStorage.setItem('refreshToken',response.refreshToken);
        //console.log(JSON.parse(response.headers.get('server')));;
      
       this.sUser.next(this.currentUser)

     })
   );
  }*/

  setCurrentUser(user:JwtUser){
    
    this.sUser.next(user);
  }
  get userValue():JwtUser{
    return this.sUser.value
  }
  login(model:any){
    return this.http.post(this.baseUrl + 'authentication/login', model).pipe(
      map((response:JwtUser)=>{
        const user = response;
        
        if(user){
          
          //localStorage.setItem()
          localStorage.setItem('mytoken', user.accessToken);
          localStorage.setItem('currentUser',JSON.stringify(user));
          this.sUser.next(user)
          this.startRefreshTokenTimer();
          return user;
        }
       
      
 
      })
    );
   }
   refreshToken(){
    /*const user: JwtUser= JSON.parse(localStorage.getItem('user'));
    if (!user){
      this.sUser.next(null);
      return;
    }
    const credentials = JSON.stringify({user});*/

    return this.http.post(this.baseUrl+ 'Token/refresh', this.userValue).pipe(
        map((response:JwtUser)=>{
          const newUser =response;
          if(newUser){
            //localStorage.setItem('user', JSON.stringify(newUser));
            localStorage.setItem('mytoken', newUser.accessToken);
            localStorage.setItem('currentUser',JSON.stringify(newUser));
            this.sUser.next(newUser);
            this.startRefreshTokenTimer();
           // console.log('refreshed');
            return newUser;
          }
        }
      ));
      
    
   }

  tryRefreshingTokens(user:JwtUser){
    return this.http.post(this.baseUrl+ 'Token/refresh', user).pipe(
      map((response:JwtUser)=>{
        const newUser =response;
        if(newUser){
          //localStorage.setItem('user', JSON.stringify(newUser));
          localStorage.setItem('mytoken', newUser.accessToken);
          localStorage.setItem('currentUser',JSON.stringify(newUser));
          this.sUser.next(newUser);
          this.startRefreshTokenTimer();
         // console.log('refreshed');
          return newUser;
        }
      }
    ));
     
   }
   

   private refreshTokenTimeout;

    public startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.userValue.accessToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
  loggedIn(): boolean {
    const token = localStorage.getItem('mytoken');
    
    
    return !this.helper.isTokenExpired(token);
  }

  isAdmin():boolean {
    const user:JwtUser = JSON.parse(localStorage.getItem('currentUser'));



    if(user.role=="SurgeryAdmin"|| user.role== "Doctor" || user.role=="Assistant"){
      
      return true;
    }

return false;
  }

  isSuperAdmin():boolean {
    const user:JwtUser = JSON.parse(localStorage.getItem('currentUser'));
    if(user.role=="SurgeryAdmin"){

      return true;
    }

return false;
  }
  /*getCurrentUser():IUser{
    const token= localStorage.getItem('mytoken');
    const decodedToken= this.helper.decodeToken(token);
   this.currentUser.userName=decodedToken.given_name;
   this.currentUser.roles[0]=decodedToken.role;
   this.currentUser.email= decodedToken.email;
   return this.currentUser;
  }*/
  public logout = () => {
   // localStorage.removeItem("mytoken");
    //localStorage.removeItem('refreshToken');
    this.stopRefreshTokenTimer();
    localStorage.removeItem('mytoken');
    localStorage.removeItem('currentUser');
    this.sUser.next(null);
    this.router.navigate(['login'])
    
  }
  public forgotPassword = (model: ForgotPassword) => {
    return this.http.post(this.baseUrl + 'authentication/forgotpassword', model);
  }
  public resetPassword = (model: ResetPassword) => {
    return this.http.post(this.baseUrl + 'authentication/resetpassword', model);
  }

  addNewUser(user:any):Observable<IUser>{

  return this.http.post<IUser>(this.baseUrl + 'authentication/register/', user)
  }
  updateUser(userId:string, user:any){
    return this.http.put(this.baseUrl +'authentication/rootpr/'+userId,user);
    }

  public getUsers(){

    return this.http.get<IUser[]>(this.baseUrl + 'authentication/all-users');

  }
  public deleteUser(userId:string){
    return this.http.delete(this.baseUrl+ 'authentication/delete-user/'+userId);
  }

  


  
}
