<div class="row container mt-5">

    <main class="col-12">
      <div class="heading"> 
        <h2>Staff List </h2>
      </div>
      <form class =" " #loginForm="ngForm" (ngSubmit)="loadStaff()" autocomplete="off"> 
                        
        <div class="mb-3">
            <label for="InputEmail1" class="form-label">Search by Staff ID number,email or phone number</label>
            <input name="searchTerm"
            [(ngModel)]="searchTerm"
            type="text" class="form-control" placeholder="enter search term here" >
            
          </div>
        
    
    </form>
        <div class="table-responsive">
          <table class="table table-striped table-sm" *ngIf="staff?.length>0;else elseBlock">
            <thead>
              <tr>
                <th>ID#</th>
                <th>Fullname</th>
                <th>Phone</th>
                <th>emailAddress</th>
                <th>Position</th>
              </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let staff of staff" routerLink='/staff/{{staff.staffID}}' >
                  <td>{{staff.staffID}}</td>
                  <td>{{staff.firstName +" "+ staff.lastName}} </td>
                  <td>{{staff.phone}}</td>
                  <td>{{staff.emailAddress}}</td>
                  <td>{{staff.position}}</td>
                
                  
                </tr>
            </tbody>
        </table>
        <ng-template #elseBlock>
          <div class="alert alert-info" role="alert">
           Oops!! No records found.
          </div>

        </ng-template>
        </div>
        <div class="d-flex justify-content-center" *ngIf="pagination">
          <pagination [boundaryLinks]="true"
           [totalItems]="pagination.totalCount"
           [itemsPerPage]="pagination.pageSize"
           [(ngModel)]="pagination.currentPage"
           (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>
      </div>
    </main>
    </div>