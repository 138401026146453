import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';
import { Person } from 'src/app/interfaces/person';
import { PatientService } from 'src/app/_services/patient.service';

import { PersonService } from 'src/app/_services/person.service';

const personType:string='patient';

@Component({
  selector: 'app-patient-new',
  templateUrl: './patient-new.component.html',
  styleUrls: ['./patient-new.component.css']
})
export class PatientNewComponent implements OnInit {
  @ViewChild('patientAdd') patientAdd:NgForm;
  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.patientAdd.dirty){
      $event.returnValue=true;
    }
  }
  createPatientForm:FormGroup;
patient:Patient;


  constructor(private toastService:ToastrService,private patientService:PatientService,private route:Router) { }

  ngOnInit(): void {
    this.initilizePatientForm();
  }

  initilizePatientForm(){
    this.createPatientForm= new FormGroup(
      {
       
        firstName:new FormControl('',Validators.required),
        lastName : new FormControl(),
        title :new FormControl(), 
        dateOfBirth:new FormControl(),
        gender :new FormControl(),
        nationalIdentityNumber:new FormControl(),
        passportNumber:new FormControl(),
        phone:new FormControl(),
        emailAddress: new FormControl(),
        occupation:new FormControl(),
        employer:new FormControl(),
      
        
        physicalAddress:new FormGroup({
          unitNumber:new FormControl(-1),
          unitName : new FormControl(),
          streetAddress : new FormControl(),
          addressLineTwo :new FormControl(),
          postalCode : new FormControl(),
          suburb : new FormControl(),
          province :new FormControl(),
          country : new FormControl()
        })
       ,
        medicalAid:new FormGroup({
          medicalAidName  : new FormControl(),
          medicalAidNumber  : new FormControl(),
          medicalAidMainMember  : new FormControl(),
          medicalAidMainMemberFullName  : new FormControl(),
          medicalAindMainMemberIdentityNumber  : new FormControl(),
          occupation  : new FormControl(),
          postalAddress  :new FormControl(),
          employer  : new FormControl(),
          residentaillAddress  :new FormControl(),
         
          phoneNumber  : new FormControl()
        }),
        patientNextOfKin: new FormGroup({
          fullName: new FormControl(),
          emailAddress: new FormControl(),
          phoneNumber:new FormControl(),
          fullAddress:new FormControl(),
          relationship: new FormControl()

        })
        ,
    patientRefDoctor:  new FormGroup({
      name:new FormControl(),
      fullName:new FormControl(),
      practiceNumber:new FormControl(),
      emailAddress:new FormControl(),
      fullAddress:new FormControl()
        })
      }
    )}
    
    
addNewPatient(){
 
  this.patientService.createPatient(this.createPatientForm.value).subscribe(response=>{
    this.patient=response;
    this.toastService.success('Patient with Id: '+this.patient.patientID+ 'hass been successfully created.', 'Success')
    this.route.navigate(['patients/'+this.patient.patientID])
  },error=>{
    this.toastService.error(error.error);
    
  });
}
}
