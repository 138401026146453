import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../interfaces/pagination';
import { Staff } from '../interfaces/staff';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  baseurl:string= environment.baseUrl;
 staff:Staff;
  paginatedResult:PaginatedResult<Staff[]>= new PaginatedResult<Staff[]>();
  


  constructor(private http:HttpClient) { }
  getStaff(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Staff[]>(this.baseurl +'staff/',{observe:'response',params}).pipe(
      map(response=>{
        this.paginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        //console.log(JSON.parse(response.headers.get('pagination')));
        return this.paginatedResult;
      })
    )
  }

 /* getPeople(personType:string){
   return  this.http.get<Person[]>(this.baseurl +'designations/'+personType+'/details',
     httOptions
  );
}*/

getStaffByID(staffId:number){
  return this.http.get<Staff>(this.baseurl + 'staff/'+staffId)
}

updateStaff(staffId:number, staff:any){
return this.http.put(this.baseurl +'staff/'+staffId,staff);
}

addStaff(staff:any):Observable<Staff>{
  return this.http.post<Staff>(this.baseurl + 'staff/', staff)
}
}
