<div class="row container-fluid mt-5">
 
  <hr>
  <main class="col-12 main-row">
    <div class="heading"> 
      <h2>Quick Actions </h2>
    </div>
    <div class="row">
      <div class="col-12 dashboard-row1">
       
        <hr>
      </div>
      <div class="col-3 dashboard-row1">
        <a class="nav-link " routerLink="/new-patient" routerLinkActive="active">
          <i class="fa fa-user-plus" aria-hidden="true"> </i> <Span>Add Patient</Span></a>
      </div>
      <div class="col-3 dashboard-row1">
        <a class="nav-link " routerLink="/new-staff" routerLinkActive="active">
        <i class="fa fa-user-plus" aria-hidden="true"> </i> <Span>Add Staff</Span></a>
      </div>
      <div class="col-3 dashboard-row1">
        <a class="nav-link " routerLink="/my-tasks" routerLinkActive="active">
        <i class="fa fa-tasks" aria-hidden="true"></i> <Span>Manage Tasks</Span></a>
      </div>
      <div class="col-3 dashboard-row1">
        <a class="nav-link " routerLink="/payments" routerLinkActive="active">
        <i class="fa fa-folder-open-o" aria-hidden="true"></i><Span>Manage Payments</Span></a>
      </div>
    </div>
    <!--End first row-->

    <!--Second Row Statistics-->
    <div class="row">

   
      <div class="col" *ngIf="authenticationService.isAdmin()">
        <div class="card" routerLink='/payments'>
         
          <div  class="card-body">
            <blockquote class="blockquote mb-0">
              <h3 class="text-success">R{{dashStats?.totalPayments}}</h3> 
              <p>Payments</p>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="col" *ngIf="authenticationService.isAdmin()">
        <div class="card" routerLink='/manage-invoices'>
         
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <h3>R{{dashStats?.invoicesTotal}}</h3> 
              <p>Invoices</p>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="col" *ngIf="authenticationService.isAdmin()">
        <div class="card" routerLink='/manage-invoices'>
         
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <h3 class="text-danger">R{{dashStats?.totalBalance}}</h3> 
              <p>Balance</p>
            </blockquote>
          </div>
        </div>
      </div>
   
      <div class="col">
        <div class="card" routerLink='/appointment'>
         
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <h3 class="text-primary"> {{dashStats?.appointmentsScheduled}}</h3> 
              <p>Appointments</p>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" routerLink='/my-tasks'>
         
          <div class="card-body" >
            <blockquote class="blockquote mb-0">
              <h3 class="text-primary">{{dashStats?.myTasks}}</h3> 
              <p>Tasks</p>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" routerLink='/patients'>
         
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <h3 class="text-dark">{{dashStats?.patientsCount}}</h3> 
              <p>Patients</p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <!--End of second row stats-->

    <div class="row mt-5">
      <div class="col-12 dashboard-row1">
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <div><i class="fa fa-bar-chart" aria-hidden="true"></i><Span class="span-heading">Invoices Overview</Span>
              </div>
              <tr>
                <th>Due Date</th>
                <th>Invoice ID</th>
                <th>Patient</th>
                <th>Invoice Total</th>
                <th *ngIf="authenticationService.isAdmin()">Amount Paid</th>
                <th>Balance</th>
               

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of invoices" routerLink='/patients/{{invoice.patientID}}'>
                <td>{{invoice.dueDate | date}}</td>
                <td>{{invoice.invoiceID}} </td>
                <td  routerLink='/patients/{{invoice.patientID}}'><span class="text-primary">{{invoice.patientID}}</span></td>
                <td>{{invoice.invoiceTotal}}</td>
                <td *ngIf="authenticationService.isAdmin()">{{invoice.amountPaid}}</td>
                <td>{{invoice.balance}}</td>
               </tr>
            </tbody>
          </table>
        </div><!--End of table div-->
        <div class="d-flex justify-content-center" *ngIf="paginationInvoice"> <!--Pagination Invoices-->
          <pagination [boundaryLinks]="true"
           [totalItems]="paginationInvoice.totalCount"
           [itemsPerPage]="paginationInvoice.pageSize"
           [(ngModel)]="paginationInvoice.currentPage"
           (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>
        </div>

        <ng-template #template>
          <div class="modal-header">
            <h4 class="modal-title pull-left">Create Invoice</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-info" *ngIf="createInvoiceForm.touched">
              <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
          </div>
            <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='newInvoice()' autocomplete="off"> 
                            
              <div class="mb-3">
                  <label for="InputEmail1" class="form-label">Dute Date</label>
                  <input formControlName='dueDate'
                  type="datetime" class="form-control" placeholder="" >
                  
                </div>
                <div class="mb-3">
                  <label  class="form-label">Amount Paid</label>
                  <input formControlName='amountPaid' type="number" class="form-control" placeholder="0">
                </div> 
                <div
                formArrayName="invoiceItems"
                *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
                >
                <div [formGroupName]="i">
                  <input formControlName="item" placeholder="Item name">
                  <input formControlName="description" placeholder="Item description">
                  <input formControlName='quantity' placeholder="qty">
                  <input formControlName="unityPrice" placeholder="Item price">
                </div>
              </div>
              <button type="button" (click)="addItem()">Add Item</button>
          
          
              <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
          
          </form>
          <p> {{createInvoiceForm.value | json}}</p>
          </div>
        </ng-template> <!--End of Create Invoice template-->
        
        
        <ng-template #updateInvoiceTemplate bsModal  class="modal fade" tabindex="-1"
        role="dialog" aria-labelledby="dialog-sizes-name1" >
         
         
          <div class="modal-header">
            <h4 class="modal-title pull-left">Update Invoice</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body ">
            <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='updateInvoice()' autocomplete="off"> 
                            
              <div class="mb-3">
                  <label for="InputEmail1" class="form-label">Dute Date</label>
                  <input  name='dueDate' formControlName='dueDate' [(ngModel)]="selectedInvoice.dueDate"
                  type="datetime" class="form-control" placeholder="" >
                  
                </div>
                <div class="mb-3">
                  <label  class="form-label">Amount Paid</label>
                  <input name="amountPaid" formControlName='amountPaid' [(ngModel)]="selectedInvoice.amountPaid" type="number" class="form-control" placeholder="0">
                </div> 
                
                <div
                formArrayName="invoiceItems"
                *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
                >
                <div [formGroupName]="i"  >
                  <input name="item" formControlName="item"   placeholder="Item name">
                  <input formControlName="description" placeholder="Item description">
                  <input formControlName='quantity' placeholder="qty">
                  <input formControlName="unityPrice" placeholder="Item price">
                  <button type="button" (click)="removeItem(i)">Delete</button>
                </div>
              </div>
              <button type="button" (click)="addItem()">Add Item</button>
          
          
              <button type="submit" class=" btn btn-lg btn-primary" >Update</button>
          
          </form>
          <p> {{createInvoiceForm.value | json}}</p>
          </div>
        
        </ng-template>
        

      </div>
      <div class="col-12 dashboard-row1 mt-5">
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <div><i class="fa fa-check" aria-hidden="true"></i><Span class="span-heading">Tasks</Span></div>
              <tr>
                <th>Status</th>
                <th>Start</th>
                <th>End</th>
                <th>Task</th>
                <th>Assigned</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let task of tasks" routerLink='/my-tasks'>
                <td>{{task.status}}</td>
                <td>{{task.startDate | date}}</td>
                <td>{{task.finishDate |date}}</td>
                <td>{{task.taskName}}</td>
                <td>{{task.staff.firstName}}</td>
            

              </tr>
            </tbody>
          </table>
        </div> <!--End of tasks table Div-->

        <div class="d-flex justify-content-center" *ngIf="paginationTasks"> <!--Pagination Invoices-->
          <pagination [boundaryLinks]="true"
           [totalItems]="paginationTasks.totalCount"
           [itemsPerPage]="paginationTasks.pageSize"
           [(ngModel)]="paginationTasks.currentPage"
           (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>
        </div>
      </div>

    </div>
    <!--End 2nd row-->
    <!--div echarts [options]="chartOption" class="demo-chart"></div-->
    
  </main>
</div>