import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  model:any={};
  loggedIn:boolean;

  constructor(private authenticationService:AuthenticationService) { }

  ngOnInit(): void {
    
  }
login(){
  this.authenticationService.login(this.model).subscribe(response=>{
    console.log(response);
    this.loggedIn=true;
  },error=>{
    console.log(error)
  }
  );
}
logout(){
  this.loggedIn=false;

}
}
