import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Invoice } from '../interfaces/invoice';
import { PaginatedResult } from '../interfaces/pagination';
import { Patient } from '../interfaces/patient';
import { PatientRecord } from '../interfaces/patient-record';


@Injectable({
  providedIn: 'root'
})
export class PatientService {
 baseurl:string= environment.baseUrl;
patient:Patient;
paginatedResult:PaginatedResult<Patient[]>= new PaginatedResult<Patient[]>();
  
  constructor(private http:HttpClient) { }
  getPatients(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Patient[]>(this.baseurl +'patients',{observe:'response',params}).pipe(
      map(response=>{
        this.paginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        //console.log(JSON.parse(response.headers.get('pagination')));
        return this.paginatedResult;
      })
    )
  }

  getPatientById(patientId:number){
    return this.http.get<Patient>(this.baseurl + 'patients/'+patientId )
  }

  createInvoiceForPatient(personID:number, patientId:number,invoice:any){
    return this.http.post<Invoice>(this.baseurl + 'designations/patients/'+personID+'/invoices/'+patientId, invoice)
  }

  updateInvoiceForPatient(personID:number,invoiceID:number,invoice:any){
    return this.http.put(this.baseurl + 'designations/patients/'+ personID+'/invoices/'+invoiceID,invoice);
  }

  createPatientRecord(personID:number,patientID:number,patientRecord:any){
    return this.http.post<PatientRecord>(this.baseurl+'designations/patients/'+personID+'/records/'+patientID, patientRecord );
  }
  updatePatientRecord(personID:number,patientRecordID:number,patientRecord:any){
    return this.http.put(this.baseurl+'designations/patients/'+personID+'/records/'+patientRecordID, patientRecord  );
  }

  createPatient(patient:any){
    return this.http.post<Patient>(this.baseurl+ 'patients/',patient);
  }
  updatePatient(patientID:number,patient:any){
    return this.http.put(this.baseurl+ 'patients/'+patientID,patient);
  }

  deletePatient(patientId:number){
    return this.http.delete(this.baseurl+ 'patients/'+patientId);
  }
}
