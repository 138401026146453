import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Invoice } from 'src/app/interfaces/invoice';
import { Pagination } from 'src/app/interfaces/pagination';
import { Payment } from 'src/app/interfaces/payment';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { PaymentService } from 'src/app/_services/payment.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {

  payments:Payment[];
  invoices:Invoice[];
  pageNumber?:number=1;
  pageSize?:number=50;
  searchTerm?:string=null;
  public paginationPayment:Pagination;
  createPaymentForm:FormGroup;

  payment:Payment;

  selectedPayment?:Payment;
  //taskName:FormControl;

  
 modalRef: BsModalRef;

  
 
 openModal(templateCreatePayment: TemplateRef<any>) {
   this.modalRef = this.modalService.show(templateCreatePayment,{class: 'modal-lg'});
   
 }
hideModal(){
 this.modalRef.hide();
 this.createPaymentForm.reset();

 
}
openModalUpdateTask(updatePaymentTemplate: TemplateRef<any>,payment:Payment) {


  this.selectedPayment=payment;
  
  this.modalRef = this.modalService.show(updatePaymentTemplate,{class: 'modal-lg'});
  console.log(this.selectedPayment);

}

  constructor(private paymentService:PaymentService, private toastService:ToastrService,
    private modalService:BsModalService,
    public datepipe: DatePipe,
    private formBuilder:FormBuilder,private invoiceService:InvoiceService) { }

  ngOnInit(): void {
    this.getPayments();
    this.getInvoices();
    this.initializePaymentForm();
     
  }

  newPayment(){
 
   this.paymentService.createPayment(this.createPaymentForm.get('invoiceID').value, 
   this.createPaymentForm.value).subscribe(response=>{
    this.payment=response;
    this.createPaymentForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },2000);
    this.toastService.success("Payment Added Successfully","Success")

   }, error=>{
     this.toastService.error(error);
   })
   //console.log(this.createTaskForm.value)
  }
  updatePayment(){
    this.paymentService.updatePayment(this.selectedPayment.paymentID,
      this.createPaymentForm.value).subscribe(()=>{
        this.toastService.success('Payment Updated successfully','Payment Update')
      }, error=>{
        this.toastService.error(error);});

  }

  initializePaymentForm(){
    this.createPaymentForm= new FormGroup(
      {
        
        date: new FormControl(),
        amountPaid:new FormControl(),
        paymentMethod: new FormControl(),
        note: new FormControl(),
        invoiceID: new FormControl(),
    
      }
    )
  }
  getPayments(){
    this.paymentService.getPayments(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
      this.payments=response.result;
      this.paginationPayment=response.pagination;
      console.log(this.payments);
    },error=>{
      this.toastService.error(error);
      
    })
  }
  getInvoices(){this.invoiceService.getInvoices(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
    this.invoices=response.result;
    //this.paginationInvoice=response.pagination;
  },error=>{
    this.toastService.error(error);
    
  })

  }
  
  pageChanged(event){
    this.pageNumber=event.page;
    this.getPayments();
  }

}
