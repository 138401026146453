import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model:any={};
  loggedIn:boolean;
  constructor(private authenticationService:AuthenticationService, private router:Router,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  login(){
    this.authenticationService.login(this.model).subscribe(response=>{
     // console.log(this.model);
      //console.log(response);
      //this.loggedIn=this.authenticationService.loggedIn();
      this.router.navigate(['dashboard']);
    },error=>{
      this.toastr.error(error.error);
    }
    );  
  }
  getCurrentUser(){
    this.loggedIn= this.authenticationService.loggedIn();
    if (this.loggedIn){
      this.router.navigate(['dashboard']);
    }
  }
  

}
