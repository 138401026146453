import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Staff } from 'src/app/interfaces/staff';

import { Person } from 'src/app/interfaces/person';

import { StaffService } from 'src/app/_services/staff.service';
import { PersonService } from 'src/app/_services/person.service';

@Component({
  selector: 'app-staff-edit',
  templateUrl: './staff-edit.component.html',
  styleUrls: ['./staff-edit.component.css']
})
export class StaffEditComponent implements OnInit {

  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.createStaffForm.dirty){
      $event.returnValue=true;
    }
  }

  createStaffForm:FormGroup;
  @Input() staff:Staff;
  
 
  initilizestaffForm(){
    this.createStaffForm= new FormGroup(
      {
       
        firstName:new FormControl(),
        lastName : new FormControl(),
        title :new FormControl(), 
        dateOfBirth:new FormControl(),
        gender :new FormControl(),
        nationalIdentityNumber:new FormControl(),
        passportNumber:new FormControl(),
        phone:new FormControl(),
        emailAddress: new FormControl(),
        occupation:new FormControl(),
        employer:new FormControl(),
        position:new FormControl(),
        practiceNumber:new FormControl()
      
      }
    )}

    setstaffFormValue(){
      this.createStaffForm.patchValue({
        'firstName':this.staff.firstName,
        'lastName':this.staff.lastName,
        'title':this.staff.title,
        'dateOfBirth':this.staff.dateOfBirth,
        gender :this.staff.gender,
        'nationalIdentityNumber':this.staff.nationalIdentityNumber,
        'passportNumber':this.staff.passportNumber,
        'phone':this.staff.phone,
        'emailAddress': this.staff.emailAddress,
        'occupation':this.staff.occupation,
        'employer':this.staff.employer,
        position:this.staff.position,
        practiceNumber:this.staff.practiceNumber
        
      })

    }

  constructor(private router:Router, private staffService:StaffService,private toastService:ToastrService) { }

  ngOnInit(): void {
    this.initilizestaffForm();
    this.setstaffFormValue();
  }
  updateStaff(){
   this.staffService.updateStaff(this.staff.staffID,this.createStaffForm.value).subscribe(()=>{
    this.toastService.success('Your staff been successfully updated.', 'Success');
    this.createStaffForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },3000);
    
    console.log(this.createStaffForm.dirty);
    },error=>{
      this.toastService.error(error);
      
   })
  // console.log(this.createstaffForm.value);
  }
  
  

}
//console.log(this.staffEdit.value);