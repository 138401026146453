import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Invoice } from '../interfaces/invoice';
import { PaginatedResult } from '../interfaces/pagination';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  baseurl:string= environment.baseUrl;
  invoice:Invoice;
  paginatedResult:PaginatedResult<Invoice[]>= new PaginatedResult<Invoice[]>();

  constructor(private http:HttpClient) { }

  getInvoices(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Invoice[]>(this.baseurl +'invoices',{observe:'response',params}).pipe(
      map(response=>{
        this.paginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        //console.log(JSON.parse(response.headers.get('pagination')));
        return this.paginatedResult;
      })
    )
  }
  getInvoiceByID(invoiceId:number){
    return this.http.get<Invoice>(this.baseurl + 'invoices/'+invoiceId)
  }

  createInvoice(patientId:number,invoice:any){
    return this.http.post<Invoice>(this.baseurl+ 'invoices/'+patientId,invoice);
  }

  updateInvoice(invoiceId:number,invoice:any){
    return this.http.put(this.baseurl+ 'invoices/'+invoiceId,invoice);
  }

}
