import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/interfaces/pagination';
import {NgForm} from '@angular/forms';
import { PatientService } from 'src/app/_services/patient.service';
import { Patient } from 'src/app/interfaces/patient';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

const patient:string='patient';
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  public patients: Patient[];
  public pagination:Pagination;
  pageNumber?:number=1;
  pageSize?:number=50;
  searchTerm?:string=null;

  constructor( private patientService:PatientService, public authService:AuthenticationService,
     private toastService:ToastrService) { }

  ngOnInit(): void {
    this.loadPatients();
    //console.log(this.pagination.totalCount)
  }

  loadPatients(){
    this.patientService.getPatients(this.pageNumber,this.pageSize,this.searchTerm).subscribe(response=>{
     // console.log(response);
      this.patients=response.result;
      this.pagination=response.pagination;
      //console.log(this.searchTerm);
      
    },error=>{
      
      
    })
  }
  pageChanged(event){
    this.pageNumber=event.page;
    this.loadPatients();
  }

  deletePatient(patient:Patient){
    this.patientService.deletePatient(patient.patientID).subscribe(response=>{
      this.toastService.success("Success","Patient Successfully deleted");
      this.patients.splice(this.patients.indexOf(patient),1);
    },error=>{
      this.toastService.error(error)
    });
  }

  /*pageChanged(event:any){
    console.log(event + "see event");
    
    this.pageNumber=event.pageNumber;
    this.loadPatients();
  }*/

}
