<div class="containter-fluid">

  <div class="table-responsive">
    
    <h4 class="mt-5 col" >Invoice Records for : {{patient.firstName}}</h4> 
               
    <hr>
    <button type="button" class="btn btn-primary" (click)="openModal(template)">Create New Invoice</button>
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>Invoice ID#</th>
          <th>Invoice Total</th>
          <th *ngIf="authenticationService.isAdmin()">Amount Paid</th>
          <th>Balance</th>
          <th>Patient ID</th>
          <th>Due Date</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        <tr  *ngFor="let invoice of invoices" 
        >
          <td>{{invoice.invoiceID}}</td>
          <td>{{invoice.invoiceTotal}}</td>
          <td *ngIf="authenticationService.isAdmin()">{{invoice.amountPaid}}</td>
          <td>{{invoice.balance}} </td>
          <td>{{invoice.patientID}}</td>
          <td>{{invoice.dueDate}}</td>
          <td><button *ngIf="authenticationService.isAdmin()" type="button" class="btn-outline-dark"  (click)='openModalInvice(updateInvoiceTemplate,invoice)'>Edit</button>
            <button (click)="generatePdf(invoice)" class="btn-outline-dark"> Print</button></td>
        
        </tr>
      </tbody>
  </table>
  </div>

 
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Invoice</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-info" *ngIf="createInvoiceForm.touched">
      <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
  </div>
    <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='newInvoice()' autocomplete="off"> 
                    
      <div class="mb-3">
          <label for="InputEmail1" class="form-label">Due Date</label>
          <input formControlName='dueDate'
          type="date" class="form-control" placeholder="" >
          
        </div>
        <div class="mb-3">
          <label  class="form-label">Amount Paid</label>
          <input formControlName='amountPaid' type="number" class="form-control" placeholder="0">
        </div> 
        <div
        formArrayName="invoiceItems"
        *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
        >
        <div [formGroupName]="i">
          <input formControlName="item" placeholder="Tariff Code">
          <input formControlName="icd_10_Code" placeholder="ICD 10 Code Code">
          <input formControlName="description" placeholder="Item description">
          <input formControlName='quantity' placeholder="qty">
          <input formControlName="unityPrice" placeholder="Item price">
          <button type="button" (click)="removeItem(i)">Delete</button>
        </div>
      </div>
      <button type="button" (click)="addItem()">Add Item</button>
  

      
     
      <div class="mb-3">
        <label  class="form-label">Terms | Extra Information</label>
        <textarea formControlName='invoiceTerms' class="form-control">                             
        </textarea>
      </div> 
  
      <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
  
  </form>
 
  </div>
</ng-template> <!--End of Create Invoice template-->


<ng-template #updateInvoiceTemplate bsModal  class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-sizes-name1" >
 
 
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Invoice For : {{patient.firstName}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='updateInvoice()' autocomplete="off"> 
                    
      <div class="mb-3">
          <label for="InputEmail1" class="form-label">Dute Date</label>
          <input  name='dueDate' formControlName='dueDate' [(ngModel)]="selectedInvoice.dueDate"
          type="datetime" class="form-control" placeholder="" >
          
        </div>
       
        
        <div
        formArrayName="invoiceItems"
        *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
        >
        <div [formGroupName]="i"  >
          <input name="item" formControlName="item"   placeholder="Tariff code">
          <input formControlName="icd_10_Code" placeholder="ICD 10 Code Code">

          <input formControlName="description" placeholder="Item description">
          <input formControlName='quantity' placeholder="qty">
          <input formControlName="unityPrice" placeholder="Item price">
         
          <button type="button" (click)="removeItem(i)">Delete</button>
        </div>
      </div>
      <button type="button" (click)="addItem()">Add Item</button>
    
      <div class="mb-3 mt-5">
        <label  class="form-label">Terms | Extra Information</label>
        <textarea formControlName='invoiceTerms' name='invoiceTerms' [(ngModel)]="selectedInvoice.invoiceTerms" class="form-control">                             
        </textarea>
      </div> 
  
      <button type="submit" class=" btn btn-lg btn-primary" >Update</button>
  
  </form>

  </div>

</ng-template>

</div>
