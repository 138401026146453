import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Person } from 'src/app/interfaces/person';
import { PersonService } from 'src/app/_services/person.service';
const personType="doctor";

@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['./doctor-detail.component.css']
})
export class DoctorDetailComponent implements OnInit {
  doctor:Person;
  role=1;
  constructor(private personService:PersonService, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.loadDoctor();
  }
  loadDoctor(){
    this.personService.getPersonByID(personType,+this.route.snapshot.paramMap.get('id')).subscribe(doctorResponse=>{
      this.doctor=doctorResponse;
    })
  }

}
