<div class="row container-fluid " >
    
    <main class="col-12 main-row">
      <div class="heading"> 
        <h2>Task Records </h2>
      </div>
<div class="table-responsive">
    
               
    <hr>
    <button type="button" class="btn btn-primary" (click)="openModal(templateCreateTask)">Create New Task</button>
    <br>

    <table class="table table-striped table-sm" *ngIf="tasks?.length>0;else elseBlock">
      <thead>
        <div><i class="fa fa-check" aria-hidden="true"></i><Span class="span-heading">Tasks</Span></div>
        <tr>
          <th>Status</th>
          <th>Start</th>
          <th>End</th>
          <th>Task</th>
          <th>Assigned</th>
          <th>Option</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let task of tasks">
          <td>{{task.status}}</td>
          <td>{{task.startDate | date}}</td>
          <td>{{task.finishDate |date}}</td>
          <td>{{task.taskName}}</td>
          <td>{{task.staff.lastName}}</td>
          <td><button type="button" class="btn btn-primary"  (click)='openModalUpdateTask(updateTaskTemplate,task)'>Edit</button></td>
      

        </tr>
      </tbody>
    </table>
    <ng-template #elseBlock>
      <div class="alert alert-info" role="alert">
       Oops!! No records found.
      </div>

    </ng-template>
  </div><!--end of tasks lists-->
  <div class="d-flex justify-content-center" *ngIf="paginationMyTask"> <!--Pagination Tasks-->
    <pagination [boundaryLinks]="true"
     [totalItems]="paginationMyTask.totalCount"
     [itemsPerPage]="paginationMyTask.pageSize"
     [(ngModel)]="paginationMyTask.currentPage"
     (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
    </pagination>
  </div>


  <ng-template #templateCreateTask>
    <div class="modal-header">
      <h4 class="modal-title pull-left">New Task</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-info" *ngIf="createTaskForm.touched">
        <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
    </div>
      <form class =" " [formGroup]="createTaskForm"  (ngSubmit)='newTask()' autocomplete="off"> 
                      
        <div class="mb-3">
            <label for="taskName" class="form-label">Task Name</label>
            <input formControlName='taskName'
            type="text" class="form-control" placeholder="" >
            
          </div>
          <div class="mb-3">
            <label  class="form-label">Task Description</label>
            <input formControlName='taskDescription' type="text" class="form-control">
          </div> 
          
          <div class="mb-3" >
            <label  class="form-label"> Status  </label>
           <span></span>
            <select id="cars" class="form-control" formControlName='status' >
                <option   *ngFor="let tstatus of taskStatuses" [ngValue]="tstatus">{{tstatus}}
                  
                </option>
          
              </select>
          </div> 

          
          <div class="mb-3">
            <label  class="form-label">Start Date</label>
            <input formControlName='startDate' type="date" class="form-control">
          </div> 
          <div class="mb-3">
            <label  class="form-label">Finish Date</label>
            <input formControlName='finishDate' type="date" class="form-control">
          </div> 
          <div class="mb-3" >
            <label  class="form-label">Assigned Staff </label>
           <span></span>
            <select id="cars" class="form-control" formControlName='staffID'>
                <option   *ngFor="let doctor of doctors" [ngValue]="doctor.staffID">{{doctor.firstName}}</option>
          
              </select>
          </div> 
    
        <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
    
    </form>
    
    </div>
  </ng-template> <!--End of CreateTask template-->

  
<ng-template #updateTaskTemplate bsModal  class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-sizes-name1" > <!--Start of Update Task-->
 
 
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Task</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <div class="alert alert-info" *ngIf="createTaskForm.touched">
      <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
  </div>
    <form class =" " [formGroup]="createTaskForm"  (ngSubmit)='updateTask()' autocomplete="off"> 
                    
      <div class="mb-3">
          <label for="taskName" class="form-label">Task Name</label>
          <input formControlName='taskName' 
          type="text" class="form-control" placeholder="" >
          
        </div>
        <div class="mb-3">
          <label  class="form-label">Task Description</label>
          <input formControlName='taskDescription' type="text" class="form-control" >
        </div> 
        
        <div class="mb-3" >
          <label  class="form-label"> Status  </label>
         <span></span>
          <select id="cars" class="form-control" formControlName='status' >
              <option   *ngFor="let tstatus of taskStatuses" [ngValue]="tstatus">{{tstatus}}
                
              </option>
        
            </select>
        </div> 

        
        <div class="mb-3">
          <label  class="form-label">Start Date</label>
          <input formControlName='startDate' type="date" class="form-control">
        </div> 
        <div class="mb-3">
          <label  class="form-label">Finish Date</label>
          <input formControlName='finishDate' type="date" class="form-control">
        </div> 
        <div class="mb-3" >
          <label  class="form-label">Assigned Staff  </label>
         <span></span>
          <select id="cars" class="form-control" formControlName='staffID'>
              <option   *ngFor="let doctor of doctors" [ngValue]="doctor.staffID" >{{" Dr "+doctor.firstName}}</option>
        
            </select>
        </div> 
  
      <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
  
  </form>
  </div>

</ng-template> <!--END tASK UPDATE-->
    </main>
</div>
