<div *ngIf="patient">

    <div class="table-responsive">
    
        <h4 class="mt-5 col" >Diagnosis Records</h4> 
                   
        <hr>
        <button type="button" class="btn btn-primary" (click)="openModal(template)">Add New Patient Record</button>
        <table class="table table-striped table-sm">
          <thead>
          
          <tbody>
              <div  *ngFor= "let patientRecord of patientRecords"  >
                <tr>
                  <th>RecordID #</th>
                <td>{{patientRecord.patientRecordID}}</td>
                </tr>
                <tr>
                    <th>C/O</th>
                  <td>{{patientRecord.co}} </td>
                </tr>
                <tr>
                  <th>ADL & NEUROVEG FXN</th>
                <td>{{patientRecord.adlNeurovegFxn}} </td>
                </tr>
                <tr>
                  <th>BEHAVIOUR/SOMATIC</th>
                <td>{{patientRecord.behaviorSomatic}} </td>
                </tr>
                <tr>
                  <th>COGNITIVE FXN</th>
                  <td>{{patientRecord.cognitiveFxn}} </td>
                </tr>
                <tr>
                  <th>MOOD</th>
                  <td>{{patientRecord.mood}} </td>
                </tr>
                <tr>
                  <th>MSCE</th>
                  <td>{{patientRecord.msce}} </td>
                </tr>
                <tr>
                  <th>PHYSICAL</th>
                  <td>{{patientRecord.physical}} </td>
                </tr>
                <tr>
                  <th>DIAGNOSIS</th>
                  <td>{{patientRecord.diagnosis}} </td>
                </tr>
               
                <tr>
                  <th>MANAGEMENT</th>
                  <td>{{patientRecord.management}} </td>
                </tr>
                <tr>
                  <th>PLAN</th>
                  <td>{{patientRecord.plan}} </td>
                </tr>
               <td>
                  <button type="button" class="btn-outline-dark"  (click)='openModalRecord(updateTemplate,patientRecord)'>Edit</button>
                  <button (click)="generatePdf(patientRecord)" class="btn-outline-dark"> Print</button>
                </td>
        
              
              
              </div>
              
          </tbody>
      </table>
      </div>

      <div class="d-flex justify-content-center" *ngIf="pagination">
        <pagination [boundaryLinks]="true"
         [totalItems]="pagination.totalCount"
         [itemsPerPage]="pagination.pageSize"
         [(ngModel)]="pagination.currentPage"
         (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
        </pagination>
    </div>
    
</div>

 
<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Create Patient Review Record</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class =" " [formGroup]="createPatientRecordForm"  (ngSubmit)='newPatientRecord()' autocomplete="off"> 
        <div class="mb-3">
          <label  class="form-label">Review Date</label>
          <input formControlName='dateVisited' type="date" class="form-control" placeholder="date visited">
        </div> 
                      
        <div class="mb-3" >
          <label  class="form-label">Assigned Dr  </label>
         <span></span>
          <select id="cars" class="form-control" formControlName='assignedDoctor_Id'>
              <option   *ngFor="let doctor of doctors" [ngValue]="doctor.staffID" >{{" Dr "+doctor.firstName}}</option>
        
            </select>
        </div> 
          <div class="mb-3">
            <label  class="form-label">C/O</label>
            
            <textarea formControlName='co' class="form-control" placeholder="c/o details here..."></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">ADL & NEUROVEG FXN</label>
            <textarea formControlName='adlNeurovegFxn'  class="form-control" placeholder="ADL & NEUROVEG FXN details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">BEHAVIOUR/SOMATIC</label>
            <textarea formControlName='behaviorSomatic'  class="form-control" placeholder="BEHAVIOUR/SOMATIC details here .."></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">COGNITIVE FXN</label>
            <textarea formControlName='cognitiveFxn'  class="form-control" placeholder="Cognitive details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MOOD</label>
            <textarea formControlName='mood'  class="form-control" placeholder="MOOD details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MSCE</label>
            <textarea formControlName='msce'  class="form-control" placeholder="MSCE details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">PHYSICAL</label>
            <textarea formControlName='physical'  class="form-control" placeholder="PHYSICAL details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">DIAGNOSIS</label>
            <textarea formControlName='diagnosis'  class="form-control" placeholder="DIAGNOSISL details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MANAGEMENT</label>
            <textarea formControlName='management'  class="form-control" placeholder="MANAGEMENT details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">PLAN</label>
            <textarea formControlName='plan'  class="form-control" placeholder="PLAN details here"></textarea>
          </div> 
       
        <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
    
    </form>
    </div>
  </ng-template> <!--End of Create Invoice template-->

  <ng-template #updateTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Update Patient Record</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)=" hideModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class =" " [formGroup]="createPatientRecordForm"  (ngSubmit)='updatePatientRecord()' autocomplete="off"> 
                      
        <div class="mb-3">
          <label  class="form-label">Review Date</label>
          <input formControlName='dateVisited' type="date" class="form-control"  placeholder="date visited">
        </div> 
                      
        <div class="mb-3" >
          <label  class="form-label">Assigned Dr  </label>
         <span></span>
          <select id="cars" class="form-control" formControlName='assignedDoctor_Id'>
              <option   *ngFor="let doctor of doctors" [ngValue]="doctor.staffID" >{{" Dr "+doctor.firstName}}</option>
        
            </select>
        </div> 
          <div class="mb-3">
            <label  class="form-label">C/O</label>
            
            <textarea formControlName='co' class="form-control"  placeholder="c/o details here..."></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">ADL & NEUROVEG FXN</label>
            <textarea formControlName='adlNeurovegFxn'  class="form-control"  placeholder="ADL & NEUROVEG FXN details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">BEHAVIOUR/SOMATIC</label>
            <textarea formControlName='behaviorSomatic'  class="form-control"  placeholder="BEHAVIOUR/SOMATIC details here .."></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">COGNITIVE FXN</label>
            <textarea formControlName='cognitiveFxn'  class="form-control"  placeholder="Cognitive details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MOOD</label>
            <textarea formControlName='mood'  class="form-control"  placeholder="MOOD details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MSCE</label>
            <textarea formControlName='msce'  class="form-control"  placeholder="MSCE details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">PHYSICAL</label>
            <textarea formControlName='physical'  class="form-control"  placeholder="PHYSICAL details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">DIAGNOSIS</label>
            <textarea formControlName='diagnosis'  class="form-control"  placeholder="DIAGNOSISL details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">MANAGEMENT</label>
            <textarea formControlName='management'  class="form-control"  placeholder="MANAGEMENT details here"></textarea>
          </div> 
          <div class="mb-3">
            <label  class="form-label">PLAN</label>
            <textarea formControlName='plan'  class="form-control"  placeholder="PLAN details here"></textarea>
          </div> 
       
        <button type="submit" class=" btn btn-lg btn-primary" [disabled]="!createPatientRecordForm.dirty" >Update Patient Record</button>
    
    </form>
    </div>
    
  </ng-template> <!--End of patient Record Update template-->
 



  
  
