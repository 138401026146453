<!--Header-->
<div class="row" >
    
    <mat-toolbar class="mat-elevation-z4">
        <a class="navbar-brand" routerLink="/dashboard" routerLinkActive="active"><img src="assets/molifelogo.png" /></a>
        
        <a  class="ml-auto" mat-icon-a *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
            <mat-icon *ngIf="!sidenav.opened">
                <span style="color: black;" >menu</span>
            </mat-icon>
            <mat-icon *ngIf="sidenav.opened">
                close
            </mat-icon>
        </a>
      </mat-toolbar>

</div>

<!--End header-->

<mat-sidenav-container>
  
    <mat-sidenav #sidenav="matSidenav" >
        <div class="mo-life-nav" >
        <h4 class="name mt-2">Howdy:{{authenticationService.userValue.userName }}</h4>
        <button (click)="logout()">Logout</button>
       
        <mat-divider></mat-divider>
        <a  class="menu-a" routerLink="/dashboard" routerLinkActive="active">
            <i class="fa fa-tachometer d-inline mr-2 i-lable" aria-hidden="true"></i> <span>Dashboard</span>
        </a>
        <mat-divider></mat-divider>
        <h5 class="name">MANAGE STAFF</h5>
        <a  class="nav-link"  routerLink="/staff" routerLinkActive="active">
            <i class="fa fa-folder-open-o mr-2" aria-hidden="true"></i><span>View Staff</span>
        </a>
        <a  class="nav-link" routerLink="/new-staff" routerLinkActive="active">
            <i class="fa fa-plus mr-2" aria-hidden="true"></i><span>Add Staff</span>
        </a>
        <mat-divider></mat-divider>
        <h5 class="name">MANAGE PATIENTS</h5>
        <a  class="nav-link"  routerLink="/patients" routerLinkActive="active">
            <i class="fa fa-folder-open-o mr-2" aria-hidden="true"></i><span>View Patients</span>
        </a>
        <a  class="nav-link" routerLink="/new-patient" routerLinkActive="active">
            <i class="fa fa-plus mr-2" aria-hidden="true"></i><span>Add Patient</span>
        </a>
        <mat-divider></mat-divider>
        <h5 class="name">APPOINTMENTS</h5>
        <a  class="nav-link" routerLink="/appointment" routerLinkActive="active">
            <i class="fa fa-calendar mr-2" aria-hidden="true"></i><span>View Appointments</span>
        </a>
        <mat-divider></mat-divider>
        <h5 class="name">TASKS</h5>
        <a  class="nav-link" routerLink="/my-tasks" routerLinkActive="active">
            <i class="fa fa-tasks mr-2" aria-hidden="true"></i><span>View Tasks</span>
        </a>
        <mat-divider></mat-divider>
        <h5 class="name">ADMIN</h5>
        <a  class="nav-link" routerLink="/all-users" routerLinkActive="active">
            <i class="fa fa-gear mr-2" aria-hidden="true"></i><span>Manage Users</span>
        </a>
        <a  class="nav-link" routerLink="/manage-invoices" routerLinkActive="active">
            <i class="fa fa-money mr-2" aria-hidden="true"></i><span>Manage Invoices</span>
        </a>
        </div>
    </mat-sidenav>

  <mat-sidenav-content>
      <div class="content mat-elevation-z4">
        <router-outlet *ngIf="authenticationService.currentUser$|async"></router-outlet>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>