import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DashboardStats } from '../interfaces/dashboard-stats';

import { Mytask } from '../interfaces/mytask';
import { PaginatedResult } from '../interfaces/pagination';
import { Staff } from '../interfaces/staff';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseurl:string= environment.baseUrl;
 
  task:Mytask;

  taskPaginatedResult:PaginatedResult<Mytask[]>= new PaginatedResult<Mytask[]>();
  

  constructor(private http:HttpClient) { }

  getTasks(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Mytask[]>(this.baseurl +'tasks',{observe:'response',params}).pipe(
      map(response=>{
        this.taskPaginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.taskPaginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        //console.log(JSON.parse(response.headers.get('pagination')));
        return this.taskPaginatedResult;
      })
    )
  }
  getTaskByID(taskID:number){
    return this.http.get<Mytask>(this.baseurl + 'tasks/'+taskID);
  }
  getDoctors(position:string){
    return this.http.get<Staff[]>(this.baseurl +'staff/position/'+position);
  }
  getDashboardStats(){
    return this.http.get<DashboardStats>(this.baseurl +'dashboard-stats');
  }

  createTask(staffID:number,myTask:any){
    return this.http.post<Mytask>(this.baseurl+ 'tasks/'+staffID,myTask);
  }

  updateTask(taskID:number,myTask:any){
    return this.http.put(this.baseurl+ 'tasks/'+taskID,myTask);
  }
}
