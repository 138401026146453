import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/interfaces/pagination';
import { Staff } from 'src/app/interfaces/staff';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { StaffService } from 'src/app/_services/staff.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {

  public staff: Staff[];
  pagination:Pagination;
  pageNumber?:number=1;
  pageSize?:number=25;
  searchTerm?:string=null;

  constructor(private  authenticationService: AuthenticationService, private staffService:StaffService) { }

  ngOnInit(): void {
    this.loadStaff();
  }


  loadStaff(){
    this.staffService.getStaff(this.pageNumber,this.pageSize,this.searchTerm)
    .subscribe(staffResponse=>{
      this.staff=staffResponse.result;
      this.pagination=staffResponse.pagination;
    },error=>{
      console.log(error);
      
    })
  }

  pageChanged(event){
    this.pageNumber=event.page;
    this.loadStaff();
  }


}
