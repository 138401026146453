import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { RoleDto } from 'src/app/interfaces/role-dto';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  userRegisterForm:FormGroup;
  toppings: FormGroup;

  roles : RoleDto[]=[];
  arr2:any=[];

  constructor(private formBuilder:FormBuilder,private authenticationService:AuthenticationService,private fb: FormBuilder,
    private toastService:ToastrService) {
    this.initialiseUserRegistrationForm();
    //this.addRolesCheckboxes();
    this.toppings = formBuilder.group({
      pepperoni: false,
      extracheese: false,
      mushroom: false
    });
  }

  ngOnInit(): void {
    this.getData();
    //this.initialiseUserUpdateForm();
    this.initialiseUserRegistrationForm();
    
  }

  initialiseUserRegistrationForm(){
    let rolesFGs = this.roles.map(x => {
      return this.fb.group({
        
        name: x.name,
        isActive: x.isActive
      });
    });
  
    this.userRegisterForm= new FormGroup({
      firstName:new FormControl(),
      lastName: new FormControl(),
      userName: new FormControl(),
      password: new FormControl(),
      confirmPassword: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      roles:  this.fb.array(rolesFGs)
    })
  }
/*get rolesFormArray(){
  return this.userRegisterForm.get('roles') as FormArray;
}
addRolesCheckboxes(){
  this.myRoles.forEach(() => this.rolesFormArray.push(new FormControl(false)));
}*/
submit(){
  
}
  addNewUser() {
   this.authenticationService.addNewUser(this.userRegisterForm.value).subscribe(response=>{
     console.log(response);
     this.toastService.success("Success","User Successfully registered");
     setTimeout(()=>{
      window.location.reload();
    },3000);
   },error=>{
     this.toastService.error(error);
   });
  }
  onCheckboxChecked(event) {
   
    const checkArray:FormArray=this.userRegisterForm.get('roles') as FormArray;
    if(event.target.checked){
      checkArray.push(new FormControl(event.target.value))
    }else{
      let i:number=0;
      checkArray.controls.forEach((item:FormControl)=>{
        if(item.value==event.target.value){
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    
  }

  getData(){
    
    this.roles= [
      {
        "name": "Doctor",
        "isActive": false
      },
      {
        "name": "Assistant",
        "isActive": false
      },
      {
        "name": "SurgeryAdmin",
        "isActive": false
      },
      {
        "name": "Nurse",
        "isActive": false
      },
      {
        "name": "Receptionist",
        "isActive": false
      }
  ]
  
  }

 

   onLanguageChange(language: any, isChecked: boolean){
     //console.log((this.exampleForm.get('languages') as FormArray).getRawValue());
     console.log('test');

   }
}