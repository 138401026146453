import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IUser } from '../interfaces/iuser';
import { JwtUser } from '../interfaces/jwt-user';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!:MatSidenav;
  currentUser:IUser;
 
  isAuthenticated=false;
  signedIn=false;
  newUser:IUser;
  currentUser$:Observable<JwtUser>;

  constructor(public observer: BreakpointObserver,private router: Router, public authenticationService:AuthenticationService) { }

  ngOnInit(): void {
    //this.getUserName();
  this.currentUser$=this.authenticationService.currentUser$
    
  }
  ngAfterViewInit() {
    setTimeout(()=>{
      this.getViewPort();
    },0)
   }
  
   getViewPort(){
     this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
       if (res.matches) {
         this.sidenav.mode = 'over';
         
         this.sidenav.close();
       } else {
         this.sidenav.mode = 'side';
         this.sidenav.open();
       }
     });
   }

   /*getUserName(){
    this.currentUser=this.authenticationService.getCurrentUser();
    if(this.authenticationService.loggedIn()){
      //console.log(this.currentUser.username + this.currentUser.role);
      return this.currentUser.userName;
    }
    
    }*/
    isLoggedIn(){
      this.authenticationService.loggedIn();
    }
    logout(){
      this.authenticationService.logout();
      this.router.navigate(['login']);


      console.log("logout");
    }

}
