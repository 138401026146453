<div class="row container mt-5">

<main class="col-12">
  <form class =" " #loginForm="ngForm" (ngSubmit)="loadDoctors()" autocomplete="off"> 
                    
    <div class="mb-3">
        <label for="InputEmail1" class="form-label">Search by Doctor ID number,email or phone number</label>
        <input name="searchTerm"
        [(ngModel)]="searchTerm"
        type="text" class="form-control" placeholder="enter search term here" >
        
      </div>
    

</form>
    <div class="table-responsive">
      <table class="table table-striped table-sm" *ngIf="doctors?.length>0; else elseBlock">
        <thead>
          <tr>
            <th>ID#</th>
            <th>Fullname</th>
            <th>Phone</th>
            <th>emailAddress</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>
            <tr  *ngFor="let doctor of doctors" routerLink='/doctors/{{doctor.personID}}' >
              <td>{{doctor.personID}}</td>
              <td>{{doctor.firstName +" "+ doctor.lastName}} </td>
              <td>{{doctor.phone}}</td>
              <td>{{doctor.emailAddress}}</td>
              <td>text</td>
            
              
            </tr>
        </tbody>
    </table>
    <ng-template #elseBlock>
      <div class="alert alert-info" role="alert">
       Oops!! No records found.
      </div>

    </ng-template>
    </div>
    <div class="d-flex justify-content-center" *ngIf="pagination">
      <pagination [boundaryLinks]="true"
       [totalItems]="pagination.totalCount"
       [itemsPerPage]="pagination.pageSize"
       [(ngModel)]="pagination.currentPage"
       (pageChanged)="pageChanged($event)"
          previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
      </pagination>
  </div>
</main>
</div>