import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';

import { PatientService } from 'src/app/_services/patient.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit {
  async generatePdf(){
    const documentDefinition = {
    content: [

      //logo
      {
        image: await this.getBase64ImageFromURL(
          "assets/molifelogo.png"
        ),width:100,
        alignment : 'center'
       },
   
     {
		
			columns: [
			
         [
          {text: 'Mo-Life Surgery : ',style:'header1'},
          //heading
             {text:'Patient File # '+ this.patient.patientID,marginBottom:10}
         ]
     
       
			]
		},
    //#### Table personal Details#####
    {
      style:'table',
      table:{
        body:[
          [{text:'Personal Details',bold:true,alignment:'center',colSpan:3},{},{}],//row1

          [ {text:[{text:'Title: ',style:'bold'},this.patient.title]}, 
          {text:[{text:'Surname: ',style:'bold'}, this.patient.lastName]},
       
          {text:[{text:'Maiden Name: ',style:'bold'}, this.patient.maidenName?this.patient.maidenName:'NA']}
       
       ], //row2


        [ {text:[{text:'Name: ',style:'bold'}, this.patient.firstName  + ' ' + (this.patient.middleName?this.patient.middleName:'')]},
        {text:[{text:'Marital Status: ',style:'bold'},this.patient.maritalStatus]},
        {text:[{text:'D.O.B: ',style:'bold'},this.datePipe.transform(this.patient.dateOfBirth,'yyyy-MM-dd')]}
         ], //row3

         [ 

         {text:[{text:'Gender: ',style:'bold'},this.patient.gender]},
         {text:[{text:'ID No: ',style:'bold'},this.patient.nationalIdentityNumber],colSpan:2},{}
        
          ], //row4


          


        [{text:[{text:'Employer: ',style:'bold'},this.patient.employer]},
        {text:[{text:'Occupation: ',style:'bold'},this.patient.occupation]},
        {text:[{text:'Phone Number: ',style:'bold'},this.patient.phone]}],//row 5

        [{text:[{text:'Email: ',style:'bold'}, this.patient.emailAddress],colSpan:2},{},{}],//row6


        [{text:[{text:'Address: ',style:'bold'}, this.patient.physicalAddress.unitNumber + ' '
        +this.patient.physicalAddress.unitName + ', '+this.patient.physicalAddress.streetAddress
      +', '+this.patient.physicalAddress.suburb +', '+this.patient.physicalAddress.province +', '
    +this.patient.physicalAddress.country +','+this.patient.physicalAddress.postalCode ],colSpan:3}],//row7

      //#### Table Medical Details#####
    [{text:'Medical Aid Details',style:'header2',alignment:'center',colSpan:3},{},{}],
    [{text:[{text:'Provider: ',style:'bold'},this.patient.medicalAid.medicalAidName]},

    {text:[{text:'Medical Aid No : ',style:'bold'},this.patient.medicalAid.medicalAidNumber]}, 
    {text:[{text:'Main Member: ',style:'bold'},this.patient.medicalAid.medicalAidMainMember==true?'YES':'NO']}],//row8

    [{text:[{text:'Dependent Code : ',style:'bold'}, this.patient.medicalAid.dependentCode],colSpan:3},{},{}],//row9
    
    [{text:[{text:'Main Member Details : ',style:'bold'}],colSpan:3},{},{}],

    [{text:[{text:'Holder Name : ',style:'bold'},this.patient.medicalAid.medicalAidMainMemberFullName]},
    {text:[{text:'Holder ID No: ',style:'bold'},this.patient.medicalAid.medicalAindMainMemberIdentityNumber]},
    {text:[{text:'Holder Occupation: ',style:'bold'},this.patient.medicalAid.occupation]}],

    [{text:[{text:'Phone Number : ',style:'bold'}, this.patient.medicalAid.phoneNumber],colSpan:2},
    {text:[{text:'Employer : ',style:'bold'}, this.patient.medicalAid.employer==null?'NA':this.patient.medicalAid.employer]},{}],
    [{text:[{text:'Address: ',style:'bold'}, this.patient.medicalAid.residentaillAddress ],colSpan:3}],

          //#### Next Of Kin Details#####
    [{text:'Next Of Kin Details',style:'header2',alignment:'center',colSpan:3},{},{}],
    [{text:[{text:'Full Name: ',style:'bold'},this.patient.patientNextOfKin.fullName]},

          {text:[{text:'Email Address : ',style:'bold'},this.patient.patientNextOfKin.emailAddress]}, 
          {text:[{text:'Phone Number: ',style:'bold'},this.patient.patientNextOfKin.phoneNumber]}],
          
          [{text:[{text:'Relationship : ',style:'bold'},this.patient.patientNextOfKin.relationship],colSpan:2},{},{}],

         
          [{text:[{text:'Address: ',style:'bold'}, this.patient.patientNextOfKin.fullAddress ],colSpan:3}],

          //#### Referring Doctor Details#####
    [{text:'Referring Doctor Details',style:'header2',alignment:'center',colSpan:3},{},{}],
    [{text:[{text:'Full Name: ',style:'bold'},this.patient.patientRefDoctor.fullName]},

          {text:[{text:'Email Address : ',style:'bold'},this.patient.patientRefDoctor.emailAddress]}, 
          {text:[{text:'Practice Numbe: ',style:'bold'},this.patient.patientRefDoctor.practiceNumber]}],
         
          [{text:[{text:'Address: ',style:'bold'}, this.patient.patientRefDoctor.fullAddress ],colSpan:3}]
       

      ]
      }
    },
    
    

    ],
    styles:{
      header1:{
        fontSize:18,
        bold:true,
        marginBottom:10
      },
      header2:{
        fontSize:15,
        bold:true,
        marginTop:10
      },
      bold:{
        bold:true
      }
    }
  };
    pdfMake.createPdf(documentDefinition).open();
   }

public patient:Patient;
public maiden:boolean=false;

public patientInvoiceRecord:any;
  constructor(private patientService:PatientService, private route:ActivatedRoute, 
    private toastService:ToastrService, private datePipe:DatePipe) { }
  

  ngOnInit(): void {
    this.loadPatient();
   // console.log(this.patient);
   
    
    //console.log(this.patientRecord + 'detail test');
  
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
  loadPatient(){
    this.patientService.getPatientById(+this.route.snapshot.paramMap.get('id')).subscribe(response=>{
      this.patient=response;
      if(this.patient?.gender=="Female"){
        this.maiden=true;
      }
      
    },error=>{
      this.toastService.error(error.error);
    });
  }
}