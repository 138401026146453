<div class="row container-fluid " >
    
    <main class="col-12 main-row">
      <div class="heading"> 
        <h2>Appointments </h2>
      </div>
<div class="table-responsive">
    
               
    <hr>
    <button type="button" class="btn btn-primary" (click)="openModal(templateCreateAppointment)">Create New Appointment</button>
    <br>

    <table class="table table-striped table-sm" *ngIf="appointments?.length>0; else elseBlock">
      <thead>
        <div><i class="fa fa-check" aria-hidden="true"></i><Span class="span-heading">Appointments</Span></div>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Patient</th>
          <th>Details</th>
          <th>Assigned Doctor</th>
          <th>Option</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let appointment of appointments">
          <td>{{appointment.date| date}}</td>
          <td>{{appointment.time|  date:'h:mm a' }}</td>
          <td>{{appointment.patient.firstName+' '+ appointment.patient.lastName}}</td>
          <td>{{appointment.description}}</td>
          <td>{{appointment.assignedDoctor}}</td>
          <td><button type="button" class="btn btn-primary"  (click)='openModalUpdateTask(updateAppointmentTemplate,appointment)'>Edit</button></td>
      

        </tr>
      </tbody>
    </table>

    <ng-template #elseBlock>
      <div class="alert alert-info" role="alert">
       Oops!! No records found.
      </div>

    </ng-template>
  </div><!--end of tasks lists-->
  <div class="d-flex justify-content-center" *ngIf="paginationAppointment"> <!--Pagination Tasks-->
    <pagination [boundaryLinks]="true"
     [totalItems]="paginationAppointment.totalCount"
     [itemsPerPage]="paginationAppointment.pageSize"
     [(ngModel)]="paginationAppointment.currentPage"
     (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
    </pagination>
  </div>


  <ng-template #templateCreateAppointment>
    <div class="modal-header">
      <h4 class="modal-title pull-left">New Appointment</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-info" *ngIf="createAppointmentForm.touched">
        <p><strong>Information: </strong> Changes detected, please not any unsaved changes will be lost!</p>
    </div>
      <form class =" " [formGroup]="createAppointmentForm"  (ngSubmit)='newAppointment()' autocomplete="off"> 
                      
        <div class="mb-3">
            <label for="date" class="form-label">Date</label>
            <input formControlName='date'
            type="date" class="form-control" placeholder="" >
            
          </div>
          <div class="mb-3">
            <label  class="form-label">Time</label>
            <select id="time" class="form-control" formControlName='time' >
                <option   *ngFor="let time of times" [ngValue]="time">{{time}}
                  
                </option>
          
              </select>
          </div> 
          
          <div class="mb-3" >
            <label  class="form-label"> Patient  </label>
           <span></span>
           <select id="patient" class="form-control" formControlName='patientID'>
            <option   *ngFor="let patient of  patients" [ngValue]="patient.patientID">{{patient.firstName + ' '+ patient.lastName}}</option>
      
          </select>
          </div> 

          <div class="mb-3">
            <label  class="form-label">Details</label>
            <input formControlName='description' type="text" class="form-control">
          </div> 
          <div class="mb-3" >
            <label  class="form-label">Assigned Dr  </label>
           <span></span>
            <select id="cars" class="form-control" formControlName='assignedDoctor'>
                <option   *ngFor="let doctor of doctors" [ngValue]="doctor.lastName">{{doctor.firstName + ' '+ doctor.lastName}}</option>
          
              </select>
          </div> 
    
        <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
    
    </form>
    
    </div>
  </ng-template> <!--End of CreateTAppointemnet template-->

  
<ng-template #updateAppointmentTemplate bsModal  class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-sizes-name1" > <!--Start of Update Appointment-->
 
 
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Appointment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <div class="alert alert-info" *ngIf="createAppointmentForm.touched">
      <p><strong>Information: </strong> Changes detected, please not any unsaved changes will be lost!</p>
  </div>
    <form class =" " [formGroup]="createAppointmentForm"  (ngSubmit)='updateAppointment()' autocomplete="off"> 
                    
        <div class="mb-3">
            <label for="date" class="form-label">Date</label>
            <input formControlName='date'
            type="date" class="form-control" placeholder="" >
            
          </div>
          <div class="mb-3">
            <label  class="form-label">Time</label>
            <select id="time" class="form-control" formControlName='time' >
                <option   *ngFor="let time of times" [ngValue]="time">{{time}}
                  
                </option>
          
              </select>
          </div> 
          
          <div class="mb-3" >
            <label  class="form-label"> Patient  </label>
           <span></span>
           <select id="patient" class="form-control" formControlName='patientID'>
            <option   *ngFor="let patient of  patients" [ngValue]="patient.patientID">{{patient.firstName + ' '+ patient.lastName}}</option>
      
          </select>
          </div> 

          <div class="mb-3">
            <label  class="form-label">Details</label>
            <input formControlName='description' type="text" class="form-control">
          </div> 
          <div class="mb-3" >
            <label  class="form-label">Assigned Dr  </label>
           <span></span>
            <select id="cars" class="form-control" formControlName='assignedDoctor'>
                <option   *ngFor="let doctor of doctors" [ngValue]="doctor.lastName">{{doctor.firstName + ' '+ doctor.lastName}}</option>
          
              </select>
          </div> 
  
      <button type="submit" class=" btn btn-lg btn-primary" >Submit</button>
  
  </form>
 
  </div>

</ng-template> <!--ENDAppointment UPDATE-->
    </main>
</div>
