import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IUser } from './interfaces/iuser';
import { AuthenticationService } from './_services/authentication.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { JwtUser } from './interfaces/jwt-user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

 
  title = 'mo-life  clientApp';
  designations:any;
  //striuname:string=this.
  currentUser:IUser;

  /**
   *
   */
  constructor( private http:HttpClient, public authenticationService:AuthenticationService) { }
  ngOnInit(){
    
    this.setCurrentUser();
    this.tryRefreshTokens();
    //this.authenticationService.
  //console.log(this.authenticationService.userValue.userName);
   
  }
  
 setCurrentUser(){
   const user:JwtUser = JSON.parse(localStorage.getItem('currentUser'));
   this.authenticationService.setCurrentUser(user);
 }
  
  isLoggedIn(){
    this.authenticationService.loggedIn();
  }

  tryRefreshTokens(){
    const user:JwtUser = JSON.parse(localStorage.getItem('currentUser'));
    this.authenticationService.tryRefreshingTokens(user).subscribe(response=>{
     // console.log(response, 'called from app comp');
    });
  
  
  }
}
