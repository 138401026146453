<div class="row container mt-5" >
  
  
    <main class="col-12">
      <div>
        <tabset>
          <tab heading="Basic Information" id="tab1" >
            <div class="row">

            <h5 class="mt-5 col" >Personal Details</h5> 
            
            </div>
            
            <hr>
            <table class="table table-bordered">
              
              <tbody>
                <tr>
                
                  <td colspan="0.5"><label for="doctor" class="fw-bold">Full Name :</label> {{"  "+ doctor.firstName + " " + doctor.lastName}}</td>
             
                  <td><label for="doctor" class="fw-bold">Title :</label> {{"  " + doctor.title!==null? doctor.title:'...'}}</td>
                  <td><label for="doctor" class="fw-bold">D.O.B : </label>{{"   " +doctor.dateOfBirth |date: '  dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                 
                  <td ><label for="doctor" class="fw-bold">Gender :  </label>{{"   " +doctor.gender}}</td>
                  <td ><label for="doctor" class="fw-bold"> ID No :  </label>{{"   " +doctor.nationalIdentityNumber}}</td>
                  <td ><label for="doctor" class="fw-bold">Passport :  </label>{{"   " +doctor.passportNumber | uppercase}}</td>
                </tr>
                <tr>
                  <td colspan="2"><label for="doctor" class="fw-bold"> Employer :  </label>{{"   " +doctor.employer}}</td>
                  <td ><label for="doctor" class="fw-bold">Occupation :  </label>{{"   " +doctor.occupation}}</td>
                </tr>
                <tr>
                  <td colspan="2"><label for="doctor" class="fw-bold"> Physical Address:  </label>{{"   " +doctor.physicalAddress.unitNumber
                     + ' '+ doctor.physicalAddress.unitName + ',  ' + doctor.physicalAddress.streetAddress + ',  '+ doctor.physicalAddress.addressLineTwo
                    + ',  '+ doctor.physicalAddress.province + ',  '+ doctor.physicalAddress.country}}</td>
                  <td ><label for="doctor" class="fw-bold">Postal Code :  </label>{{"   " +doctor.physicalAddress.postalCode}}</td>
                </tr>
                <tr>
                  <td ><label for="doctor" class="fw-bold"> Contact Number :  </label>{{"   " +doctor.phone}}</td>
                  <td ><label for="doctor" class="fw-bold">Email :  </label>{{"   " +doctor.emailAddress}}</td>
                  <td ><label for="doctor" class="fw-bold">Home Telephone :  </label>{{"   0116349576" }}</td>
                </tr>
                
              </tbody>
            </table><!--end of first table basic information-->

            <!--Start of Second Table Medical AID Records-->
            <h5 class="mt-5">Medical Aid </h5>
            <h6 class="">Principa Holder Details </h6>
            <hr>
            <table class="table table-bordered">
              
              <tbody>
                <tr >
                
                  <td colspan="2"><label for="doctor" class="fw-bold">Medical Aid Provider :</label> {{"  "+ doctor.medicalAid.medicalAidName}}</td>
             
                  <td><label for="doctor" class="fw-bold">Medical Aid No :</label> {{"  " + doctor.medicalAid.medicalAidNumber}}</td>
                  
                </tr>
                <tr>
                 
                  <td *ngIf="!doctor.medicalAid.medicalAidMainMember"><label for="doctor" class="fw-bold">Main Member :  </label>No </td>
                  <td *ngIf="doctor.medicalAid.medicalAidMainMember"><label for="doctor" class="fw-bold">Main Member :  </label>YES </td>
                  
                </tr>
               

                  <tr  *ngIf="!doctor.medicalAid.medicalAidMainMember" >
                    <td colspan="2"><label for="doctor" class="fw-bold"> Main Member Details :  </label></td>
                    <td ><label for="doctor" class="fw-bold">Holder Name :  </label>{{"   " +doctor.medicalAid.medicalAidMainMemberFullName}}</td>
                  </tr>
                  <tr  *ngIf="!doctor.medicalAid.medicalAidMainMember">
                    <td colspan="1"><label for="doctor" class="fw-bold">Holder ID No:  </label>{{"   " +doctor.medicalAid.medicalAindMainMemberIdentityNumber}}</td>
                    <td ><label for="doctor" class="fw-bold">Holder Occupation :  </label>{{"   " +doctor.medicalAid.occupation}}</td>
                    <td ><label for="doctor" class="fw-bold">Holder Employer :  </label>{{"   " +doctor.medicalAid.employer}}</td>
                  </tr>
                  <tr  *ngIf="!doctor.medicalAid.medicalAidMainMember">
                    <td colspan=""><label for="doctor" class="fw-bold">Physical Addresss:  </label>{{"   " +doctor.medicalAid.residentaillAddress}}</td>
                    <td ><label for="doctor" class="fw-bold">Postal Address:  </label>{{"   " +doctor.medicalAid.postalAddress}}</td>
                    <td ><label for="doctor" class="fw-bold">Phone Number :  </label>{{"   " +doctor.medicalAid.phoneNumber}}</td>
                  </tr>

                <!--If its main main holder-->
               
                
              </tbody>
            </table>
            
          </tab>
          <tab heading="Next Of Kin Details">

            
            <!--Start of Next of kin Details-->
            <h5 class="mt-5">doctor's Next Of Kin Details </h5>
            
            <hr>
            <table class="table table-bordered">
              
              <tbody>
                <tr >
                
                  <td colspan="2"><label for="doctor" class="fw-bold">Full Name :</label> {{"  "+ doctor.nextOfKinFullName}}</td>
             
                  <td><label for="doctor" class="fw-bold">Identity No :</label> {{"  " + doctor.nextOfKinNextOfKinIdentity}}</td>
                  
                  
                </tr>
                <tr>
                  <td><label for="doctor" class="fw-bold"> Relationship :</label> Remeber to add to backend</td>
                  <td colspan=""><label for="doctor" class="fw-bold">Phone Number :</label> {{"  "+ doctor.nextOfKinPhoneNumber}}</td>
             
                  <td><label for="doctor" class="fw-bold">Email Address :</label> {{"  " + doctor.nextOfKinEmailAddress}}</td>
                </tr>

                <tr>
                  <td colspan="3"><label for="doctor" class="fw-bold"><Address></Address> Physical Address:</label> {{"  " + doctor.nextOfKinAddress}}</td>
                </tr>
               
                <!--end of next of kin Details-->
               
                
              </tbody>
            </table>

          </tab>
        
          <tab   heading="Editx Records"><app-doctor-edit [doctorFromDetail]="doctor"></app-doctor-edit></tab>
          
        </tabset>
      </div>

      
    </main>
  </div>