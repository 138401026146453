<div class="container-fluid">
 
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
          <a class="navbar-brand" href="/"><img src="assets/molifelogo.png"/></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
           
            <form #loginForm="ngForm" class="form-inline ml-auto" (ngSubmit)="login()" autocomplete="off">
               
                <button class="btn btn-outline-successml-auto" type="submit">Surgery Digital Portal</button>
              </form>
          </div>
        </div>
      </nav>
<hr>
</div>