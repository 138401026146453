<div class="container">
    <div class="d-flex justify-content-center">

        <img src = "assets/molifelogo.png"  width="300" height="auto">

   
    </div>
    <hr>
    <div class="d-flex justify-content-center">

        <h3>Login To Your Portal</h3>
  
    </div>
    <div class="row mt-5">
        <div class="col-lg">
           
          </div>
          <div class="col-lg">
            

                <form class =" " #loginForm="ngForm" (ngSubmit)="login()" autocomplete="off"> 
                    
                        <div class="mb-3">
                            <label for="InputEmail1" class="form-label">Username</label>
                            <input name="userName"
                            [(ngModel)]="model.userName"
                            type="text" class="form-control" placeholder="Username" >
                            
                          </div>
                          <div class="mb-3">
                            <label for="InputPassword" class="form-label">Password</label>
                            <input name="password" [(ngModel)]="model.password" type="password" class="form-control" placeholder="Password">
                          </div>
              
                          <button type="submit" class=" btn btn-lg btn-primary">Login</button>
                   
                  </form>

                
                  <div>
                    <a [routerLink]="['/login/forgot-password']"> Forgot Password </a>
                </div>
              
          </div>
          <div class="col-lg">
          
          </div>
    </div>

   
</div>
