<div class="container">
    <div class="d-flex justify-content-center">

        <img src = "assets/molifelogo.png"  width="300" height="auto">

   
    </div>
    <hr>
    <div class="d-flex justify-content-center">

        <h3>Mo-Life Portal</h3>
  
    </div>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">Forgot Password</h1>

            <div *ngIf="showError" class="alert alert-danger" role="alert">
                {{errorMessage}}
            </div>

            <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                {{successMessage}}
            </div>

            <form [formGroup]="forgotPasswordForm" autocomplete="off" novalidate (ngSubmit)="forgotPassword(forgotPasswordForm.value)">
                <div class="form-group row">
                    <label for="email" class="col-form-label col-sm-2">Email:</label>
                    <div class="col-md-5">
                        <input type="text" id="email" formControlName="email" class="form-control" />
                    </div>
                    <div class="col-md-5">
                        <em *ngIf="validateControl('email') && hasError('email', 'required')">Email is required</em>
                    </div>
                </div>
                <br>
                <div class="form-group row">
                    <div class="btn-group col-md-3" role="group">
                        <button type="submit" class="btn btn-info" [disabled]="!forgotPasswordForm.valid">Submit</button>
                        <button type="button" class="btn btn-danger" [routerLink]="['login']">Back To Login</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>