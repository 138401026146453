import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Appointment } from '../interfaces/appointment';
import { PaginatedResult } from '../interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  baseurl:string= environment.baseUrl;
 
 appointment:Appointment;

  appointmentPaginatedResult:PaginatedResult<Appointment[]>= new PaginatedResult<Appointment[]>();
  

  constructor(private http:HttpClient) { }

  getAppointments(pageNumber?:number,pageSize?:number,SearchTerm?:string){
    let params = new HttpParams();
    if(SearchTerm!==null){
      params=params.append('searchTerm',SearchTerm)

    }
    if (pageNumber!==null && pageSize !==null){
      params=params.append('pageNumber', pageNumber.toString());
      params=params.append('pageSize', pageSize.toString());
    
    }
    return this.http.get<Appointment[]>(this.baseurl +'appointments',{observe:'response',params}).pipe(
      map(response=>{
        this.appointmentPaginatedResult.result=response.body;
        if(response.headers.get('pagination')!==null){
          this.appointmentPaginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
        }
        //console.log(JSON.parse(response.headers.get('pagination')));
        return this.appointmentPaginatedResult;
      })
    )
  }
  getAppointmentByID(appointmentID:number){
    return this.http.get<Appointment>(this.baseurl + 'appointments/'+appointmentID);
  }
 

  createAppointment(patientID:number,appointment:any){
    return this.http.post<Appointment>(this.baseurl+ 'appointments/'+patientID,appointment);
  }

  updateAppointment(appointmentID:number,appointment:any){
    return this.http.put(this.baseurl+ 'appointments/'+appointmentID,appointment);
  }
}
