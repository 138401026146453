import { ThisReceiver } from '@angular/compiler';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Staff } from 'src/app/interfaces/staff';
import { StaffService } from 'src/app/_services/staff.service';

@Component({
  selector: 'app-staff-new',
  templateUrl: './staff-new.component.html',
  styleUrls: ['./staff-new.component.css']
})
export class StaffNewComponent implements OnInit {

  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.createStaffForm.dirty){
      $event.returnValue=true;
    }
  }
  createStaffForm:FormGroup;
staff:Staff;


  constructor(private toastService:ToastrService,private staffService:StaffService,private route:Router) { }

  ngOnInit(): void {
    this.initilizeStaffForm();
  }

  initilizeStaffForm(){
    this.createStaffForm= new FormGroup(
      {
        firstName:new FormControl(),
        lastName : new FormControl(),
        title :new FormControl(), 
        dateOfBirth:new FormControl(),
        practiceNumber: new FormControl(),
        position: new FormControl(),
        gender :new FormControl(),
        nationalIdentityNumber:new FormControl(),
        passportNumber:new FormControl(),
        phone:new FormControl(),
        emailAddress: new FormControl(),
        occupation:new FormControl(),
        employer:new FormControl(),
      
      }
    )}
    
    
addNewStaff(){
 
  this.staffService.addStaff(this.createStaffForm.value).subscribe(response=>{
    this.staff=response;
    this.toastService.success('Staff with Id: '+this.staff.staffID+ 'hass been successfully created.', 'Success')
    this.route.navigate(['staffs/'+this.staff.staffID])
  },error=>{
    this.toastService.error(error.error);
    
  });
}

}
