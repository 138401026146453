import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Person } from 'src/app/interfaces/person';
import { PersonService } from 'src/app/_services/person.service';
const patientType:string='doctor';

@Component({
  selector: 'app-doctor-edit',
  templateUrl: './doctor-edit.component.html',
  styleUrls: ['./doctor-edit.component.css']
})
export class DoctorEditComponent implements OnInit {
  @ViewChild('doctorEdit') doctorEdit:NgForm;
  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.doctorEdit.dirty){
      $event.returnValue=true;
    }
  }
  @Input() doctorFromDetail:Person;

  constructor(private router:Router, private personService:PersonService) { }

  ngOnInit(): void {
  }
  updateDoctor(){
    this.personService.updatePerson(patientType,this.doctorFromDetail.personID,this.doctorFromDetail).subscribe(()=>{
      console.log('success');
      
    })
  }
}
